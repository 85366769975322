import { ClientInfo } from '../context/client_context'
import { NationalityEnum } from '../context/supplier_context'
import { ListReturn } from '../utils/maks'
import { get, post, put } from './generic'

export class ClientEmailAlreadyInUseError extends Error {
    constructor() {
        super()
    }
}

export class ClientDocumentAlreadyInUseError extends Error {
    constructor() {
        super()
    }
}

export interface CreateClientInterface {
    nome: string
    numero_documento: string
    email: string | null
    flag_ativo: number
    flag_fiado: number
    juros_credito_dia: number
    endereco: {
        nome: string
        flag_nacionalidade: number
    }
    phones: { telefone: string }[]
    valor_credito: number
    taxa_juros: number
    flag_cobrar_juros: number
    preco_preferivel: number | null
    flag_tipo_vencimento: number
    flag_tipo_documento: number
    flag_orgao_publico: number
}

export interface UpdateClientInterface extends Partial<CreateClientInterface> {
    id_cliente: number
}

export interface ListClientsInterface {
    skip: number
    take: number
    search?: string
    dontCount?: boolean
}

export interface GenerateClientExtractsInterface {
    id: number
    data_inicial: string | null
    data_final: string | null
    status: number[] | null
    selectedFilters: Record<string, string>
}

export interface ClientApiData {
    dias_credito: number | null
    valor_credito: string | null
    credito_gasto: string | null
    email: string
    flag_ativo: number
    flag_fiado: number
    id_cliente: number
    juros_credito_dia: number
    nome: string
    numero_documento: string
    endereco: {
        flag_nacionalidade: number
        id_endereco_cliente: number
        nome: string
    }
    telefones: {
        id_cliente: number
        id_telefone_cliente: number
        telefone: string
    }[]
    taxa_juros: string
    flag_cobrar_juros: number
    preco_preferivel: number | null
    flag_tipo_vencimento: number
    flag_tipo_documento: number
    flag_orgao_publico: number
}

export interface GenerateClientsBalanceInterface {
    order?: 'balance' | 'name'
    id_filial_master?: number
}

export function parseClient(data: ClientApiData): ClientInfo {
    return {
        name: data.nome,
        document: data.numero_documento,
        address: data.endereco ? data.endereco.nome : '',
        allowCredit: Boolean(data.flag_fiado),
        creditDays: data.juros_credito_dia.toString(),
        creditValue: data.valor_credito ? Number(data.valor_credito) : 0,
        email: data.email ?? '',
        nationality:
            data.endereco && data.endereco.flag_nacionalidade === 1
                ? NationalityEnum.Native
                : NationalityEnum.Foregein,
        phoneNumbers: data.telefones?.length
            ? data.telefones.map((telefone) => telefone.telefone)
            : [''],
        id: data.id_cliente,
        creditUsed: data.credito_gasto ? Number(data.credito_gasto) : 0,
        isActive: Boolean(data.flag_ativo),
        fees: Number(data.taxa_juros) * 100,
        chargeFees: Boolean(data.flag_cobrar_juros),
        prefferedPrice: data.preco_preferivel,
        expirationType: data.flag_tipo_vencimento,
        documentType: data.flag_tipo_documento,
        isPublicOrgan: Boolean(data.flag_orgao_publico),
    }
}

export async function createClient(
    infos: CreateClientInterface
): Promise<number> {
    try {
        const res = await post('client', infos, {
            useRecaptcha: true,
        })
        return res.data.data.client.id_cliente
    } catch (error: any) {
        if (error.message.includes('email already exists'))
            throw new ClientEmailAlreadyInUseError()
        if (error.message.includes('document already exists'))
            throw new ClientDocumentAlreadyInUseError()
        throw error
    }
}

export async function updateClient(
    infos: UpdateClientInterface
): Promise<void> {
    try {
        await put('client', infos, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error.message.includes('email already exists'))
            throw new ClientEmailAlreadyInUseError()
        if (error.message.includes('document already exists'))
            throw new ClientDocumentAlreadyInUseError()
        throw error
    }
}

export async function getClientById(clientId: number): Promise<ClientInfo> {
    const res = await post('client/specific', { id: clientId })
    return parseClient(res.data.data)
}

export async function listClients(
    data: ListClientsInterface
): Promise<ListReturn<ClientInfo>> {
    const res = await post('clients', data)
    return {
        data: res.data.data.data.map((data: ClientApiData) =>
            parseClient(data)
        ),
        count: res.data.data.count,
    }
}

export async function generateClientExtract({
    id,
    data_inicial,
    data_final,
    status,
    selectedFilters,
}: GenerateClientExtractsInterface): Promise<void> {
    await post(
        `client/extract/${id}`,
        {
            data_inicial,
            data_final,
            status,
            selectedFilters,
        },
        {
            useRecaptcha: true,
        }
    )
}

export async function generateClientsBalance(
    data: GenerateClientsBalanceInterface
): Promise<void> {
    await post('client/balance', data, {
        useRecaptcha: true,
    })
}

export async function deleteClients(clients: number[]): Promise<void> {
    await post(
        'client/delete',
        { clients },
        {
            useRecaptcha: true,
        }
    )
}

export async function consultRuc(ruc: string): Promise<string | null> {
    try {
        const res = await get(`client/consult/${ruc}`, {
            useRecaptcha: true,
        })
        return res.data.data.name
    } catch (error) {
        return null
    }
}
