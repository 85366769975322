import {
    Typography,
    Box,
    DialogTitle,
    DialogContent,
    Dialog,
    Link,
    SxProps,
} from '@mui/material'
import Button from '@mui/material/Button'
import { forwardRef, ReactNode } from 'react'

interface Props {
    open: boolean
    handleClose(v: boolean): void
    handleSubmit(): void
    title: string
    textButton: string
    children: ReactNode
    handleClickSecondaryButton?(): void
    fullWidth?: boolean
    fullScreen?: boolean
    disabledButton?: boolean
}

const GenericModal = forwardRef<HTMLDivElement, Props>(
    (
        {
            handleClose,
            handleSubmit,
            title,
            textButton,
            children,
            handleClickSecondaryButton,
            open,
            fullWidth,
            fullScreen,
            disabledButton,
        },
        ref
    ) => {
        return (
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                fullWidth={fullWidth}
                fullScreen={fullScreen}
                maxWidth="xl"
                className="modal"
            >
                <DialogTitle>
                    {!!title && <Box
                        sx={{
                            color: 'text.primary',
                            fontSize: '20px',
                            fontWeight: '500',
                            textAlign: 'center',
                            borderBottom: '1px solid rgba(218, 218, 218, 0.7)',
                            paddingBottom: '10px',
                            minWidth: { xs: 'auto', sm: '350px' },
                        }}
                    >
                        {title}
                    </Box>}
                </DialogTitle>
                <DialogContent ref={ref}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {children}
                        {textButton && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: '100%',
                                    textTransform: 'none',
                                    mt: '20px',
                                    py: '10px',
                                    fontSize: '18px',
                                }}
                                onClick={() => {
                                    handleSubmit()
                                }}
                                disabled={disabledButton}
                            >
                                {textButton}
                            </Button>
                        )}
                        {handleClickSecondaryButton && (
                            <Link
                                component="button"
                                sx={{
                                    fontSize: '16px',
                                    marginTop: '30px',
                                    textDecoration: 'none',
                                    width: '100%',
                                }}
                                onClick={handleClickSecondaryButton}
                            >
                                Volver
                            </Link>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }
)

export default GenericModal
