import {
    Drawer,
    List,
    ListItemIcon,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    Collapse,
} from '@mui/material'
import { Box } from '@mui/system'
import { Navigation, navMenu } from './nav'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Header from '../Header'
import { useAccount } from '../../context/account_context'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useConfigs } from '../../context/configs_context'

interface ContentProps {
    setShowMobile(value: boolean): void
}

const DrawerContent = ({ setShowMobile }: ContentProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [openCollpase, setOpenCollapse] = useState(-1)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    const checkNav = (nav: Navigation) =>
        location.pathname === nav.link ||
        nav.subitems?.map((s) => s.link).includes(location.pathname)

    const handleNavigate = (nav: Navigation) => {
        if (nav.link) navigate(nav.link)
        else if (nav.subitems && nav.subitems.length) {
            const subitem = nav.subitems.find(
                (s) => accountInfo && s.validate(accountInfo, configs)
            )
            if (subitem?.link) navigate(subitem?.link)
        }
        setShowMobile(false)
    }

    return (
        <>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'normal',
                    overflowY: 'scroll',
                    backgroundColor: 'primary.main',
                    '::-webkit-scrollbar': {
                        width: '0px',
                    },
                }}
            >
                <Typography
                    color={'white'}
                    fontStyle={'italic'}
                    sx={{ padding: '20px', height: '70px' }}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    fontSize={'24px'}
                >
                    FR Sistema
                </Typography>
                {/* <Box
                    component="img"
                    src="/logo_naranja.png"
                    sx={{ padding: '20px', height: '70px' }}
                /> */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <List
                        sx={{
                            width: '90%',
                        }}
                    >
                        {navMenu
                            .filter(
                                (n) =>
                                    accountInfo &&
                                    n.validate(accountInfo, configs) &&
                                    !n.hide
                            )
                            .map((nav, index) => (
                                <>
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            background: checkNav(nav)
                                                ? '#FFF'
                                                : 'primary.main',
                                            borderRadius: '6px',
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                px: '20px',
                                            }}
                                            onClick={() => {
                                                if (nav.link)
                                                    handleNavigate(nav)
                                                else
                                                    setOpenCollapse((prev) =>
                                                        prev === index
                                                            ? -1
                                                            : index
                                                    )
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    width: '25px',
                                                    height: '25px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '12px',
                                                    minWidth: '25px',
                                                    color: checkNav(nav)
                                                        ? 'primary.main'
                                                        : '#FFF',
                                                }}
                                            >
                                                {nav.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={nav.text}
                                                primaryTypographyProps={{
                                                    fontSize: '14px',
                                                }}
                                                sx={{
                                                    ml: '20px',
                                                    color: checkNav(nav)
                                                        ? 'primary.main'
                                                        : '#FFF',
                                                }}
                                            />
                                            {/* {!nav.link && (
                                            <Box>
                                                {openCollpase === index ? (
                                                    <KeyboardArrowDownIcon />
                                                ) : (
                                                    <KeyboardArrowRightIcon />
                                                )}
                                            </Box>
                                        )} */}
                                        </ListItemButton>
                                    </ListItem>
                                    {nav.subitems &&
                                        nav.subitems.length > 0 && (
                                            <Collapse
                                                in={openCollpase === index}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                    }}
                                                >
                                                    <List
                                                        sx={{
                                                            width: '90%',
                                                        }}
                                                    >
                                                        {nav.subitems
                                                            .filter(
                                                                (n) =>
                                                                    accountInfo &&
                                                                    n.validate(
                                                                        accountInfo,
                                                                        configs
                                                                    ) &&
                                                                    !n.hide
                                                            )
                                                            .map(
                                                                (
                                                                    subitem,
                                                                    i
                                                                ) => (
                                                                    <ListItem
                                                                        key={i}
                                                                        disablePadding
                                                                        sx={{
                                                                            background:
                                                                                checkNav(
                                                                                    subitem
                                                                                )
                                                                                    ? '#FFF'
                                                                                    : 'primary.main',
                                                                            borderRadius:
                                                                                '6px',
                                                                        }}
                                                                    >
                                                                        <ListItemButton
                                                                            sx={{
                                                                                pl: '5px',
                                                                            }}
                                                                            onClick={() => {
                                                                                handleNavigate(
                                                                                    subitem
                                                                                )
                                                                            }}
                                                                        >
                                                                            <ListItemIcon
                                                                                sx={{
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    borderRadius:
                                                                                        '12px',
                                                                                    minWidth:
                                                                                        '30px',
                                                                                    color: checkNav(
                                                                                        subitem
                                                                                    )
                                                                                        ? 'primary.main'
                                                                                        : '#FFF',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    subitem.icon
                                                                                }
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    subitem.reducedText
                                                                                }
                                                                                primaryTypographyProps={{
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                                sx={{
                                                                                    ml: '10px',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    color: checkNav(
                                                                                        subitem
                                                                                    )
                                                                                        ? 'primary.main'
                                                                                        : '#FFF',
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                )
                                                            )}
                                                    </List>
                                                </Box>
                                            </Collapse>
                                        )}
                                </>
                            ))}
                    </List>
                </Box>
            </Box>
        </>
    )
}

interface Props {
    drawerWidth: number
    headerHeight: number
    hideSideBar: boolean
    hideHeader: boolean
}

const LeftPanel = ({
    drawerWidth,
    headerHeight,
    hideSideBar,
    hideHeader,
}: Props) => {
    const [showMobile, setShowMobile] = useState(false)

    return (
        <>
            {!hideHeader && (
                <Header
                    drawerWidth={drawerWidth}
                    setShowMobile={setShowMobile}
                    headerHeight={headerHeight}
                    hideSideBar={hideSideBar}
                />
            )}
            <Box className="leftPanel">
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={showMobile}
                    onClose={() => setShowMobile(false)}
                    PaperProps={{
                        sx: {
                            border: 'none',
                            width: drawerWidth,
                            display: {
                                xs: 'block',
                                lg: hideSideBar ? 'block' : 'none',
                            },
                        },
                    }}
                >
                    <DrawerContent setShowMobile={setShowMobile} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    anchor="left"
                    PaperProps={{
                        sx: {
                            width: drawerWidth,
                            display: {
                                xs: 'none',
                                lg: hideSideBar ? 'none' : 'block',
                            },
                            border: 'none',
                        },
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <DrawerContent setShowMobile={setShowMobile} />
                </Drawer>
            </Box>
        </>
    )
}

export default LeftPanel
