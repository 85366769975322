import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import { useNavigate } from 'react-router-dom'
import {
    getConfigs,
    listContactTypes,
    updateConfigs,
} from '../services/configs'
import { ImageInterface } from '../pages/Accounts/Register'
import { getFileInBase64 } from '../services/media'
import { getDateInIsoFormat, validateEmail, validateRuc } from '../utils/maks'

export enum SaleMethodEnum {
    Normal = 0,
    CreateAsPending = 1,
}

export enum BalanceTypeEnum {
    Weight = 0,
    Price = 1,
}

export enum SystemBranchEnum {
    NoBranch = 0,
    Branch = 1,
}

export enum PrintMethodEnum {
    Complete = 0,
    DataOnly = 1,
}

export enum UseElectronicNoteEnum {
    NotUse = 0,
    Use = 1,
    UseFromConfigs = 2,
}

export enum ContactTypeEnum {
    Email = 1,
    Phone = 2,
    Whatsapp = 3,
    Facebook = 4,
    Instagram = 5,
    Address = 6,
    City = 7,
}

export enum SearchTypeEnum {
    Normal = 0,
    Barcode = 1,
}

export enum SalePrintMethodEnum {
    Ticket = 0,
    TicketTXT = 1,
    CommonNote = 2,
    CommonNoteTXT = 3,
}

export enum ShowIdEnum {
    DontShow = 0,
    InternalCode = 1,
    Barcode = 2,
}

export enum PaymentScreen {
    Normal = 0,
    Market = 1,
}

export enum TicketType {
    Complete = 0,
    Reduced = 1,
}

export enum ProductTicketType {
    Barcode = 0,
    NoBarcode = 1,
}

export interface ContactTypeInfo {
    id: number
    name: string
}

export interface ContactInfo {
    id?: number
    value: string
    contactType: ContactTypeInfo
    contactTypeId?: number
}

export const PIX_KEY_TYPES = ['CPF', 'CNPJ', 'EMAIL', 'PHONE', 'EVP']

export interface ConfigsInfo {
    id: number
    timeZone: number
    timeZoneCountry: string
    isSummerTime: boolean
    useMasterPassword: boolean
    masterPassword: string
    companyName: string | null
    companyLogo: ImageInterface | null
    companySlogan: string | null
    companyDocument: string | null
    companyRegime: string
    companyEmissionDepartmentId: string
    companyEmissionDepartmentDescription: string
    companyEmissionCityId: string
    companyEmissionCityDescription: string
    companyEconomicActivityId: string
    companyEconomicActivityDescription: string
    companyEmissionHouseNumber: string
    companyEmissionDistrictId: string
    companyEmissionDistrictDescription: string
    useElectronicNote: number
    publicCertificateEletronicNote: string
    privateKeyEletronicNote: string
    secretCodeEletronicNote: string
    dateEletronicNote: Date | null
    saleMethod: number
    printMethod: number
    balance: {
        initialDigit: number | null
        barcodeLength: number | null
        balanceLength: number | null
        finalDigit: number | null
        balanceType: number
    }
    systemBranch: number
    ticketFontSize: number
    searchType: number
    salePrintMethod: number
    salePrintMethodPending: number
    noteAfterSale: boolean
    noteAfterFinalizeSale: boolean
    showOnlyNormalPaymentType: boolean
    printPosition: string | null
    notesQnt: number
    printHalfPage: boolean
    showIdOnNote: boolean
    showBarcodeOnNote: boolean
    showBranchStock: boolean
    showId: number
    hideWrittenTotal: boolean
    paymentScreen: number
    hidePromissoryNote: boolean
    useLogoInTicket: boolean
    qntDecimals: number
    billAnonymousClient: boolean
    isPixActivated: boolean
    pixKeyType: string | null
    pixKey: string | null
    date?: string
    hour?: string
    ticketType: number
    showSecondaryCoinTotalsTicket: boolean
    productTicketType: number
    needToChoosePaymentMethod: boolean
    contacts: ContactInfo[]
}

export interface ConfigsError {
    companyName: string
    companyDocument: string
    companyRegime: string
    companyEmissionDepartmentId: string
    companyEmissionDepartmentDescription: string
    companyEmissionCityId: string
    companyEmissionCityDescription: string
    companyEconomicActivityId: string
    companyEconomicActivityDescription: string
    companyEmissionHouseNumber: string
    companyEmissionDistrictId: string
    companyEmissionDistrictDescription: string
    publicCertificateEletronicNote: string
    privateKeyEletronicNote: string
    secretCodeEletronicNote: string
    dateEletronicNote: string
    ticketFontSize: string
    balance: {
        initialDigit: string
        barcodeLength: string
        balanceLength: string
        finalDigit: string
    }
    contacts: string[]
}

export interface ConfigsContextInterface {
    configs: ConfigsInfo
    setConfigs: Dispatch<SetStateAction<ConfigsInfo>>
    configsError: ConfigsError
    setConfigsError: Dispatch<SetStateAction<ConfigsError>>
    validate(): boolean
    handleUpdateConfigs(): Promise<void>
    contactTypes: ContactTypeInfo[]
    base64Logo: string
}

export const contactInfoDefault: ContactInfo = {
    value: '',
    contactType: { id: 0, name: '' },
}

const configsDefault: ConfigsInfo = {
    id: 0,
    timeZone: 0,
    timeZoneCountry: '',
    useMasterPassword: false,
    masterPassword: '',
    isSummerTime: false,
    saleMethod: SaleMethodEnum.Normal,
    printMethod: PrintMethodEnum.Complete,
    balance: {
        initialDigit: null,
        barcodeLength: null,
        balanceLength: null,
        finalDigit: null,
        balanceType: BalanceTypeEnum.Weight,
    },
    contacts: [{ ...contactInfoDefault }],
    systemBranch: SystemBranchEnum.NoBranch,
    companyName: '',
    companyLogo: null,
    companySlogan: '',
    companyDocument: '',
    companyRegime: '',
    companyEmissionDepartmentId: '',
    companyEmissionDepartmentDescription: '',
    companyEmissionCityId: '',
    companyEmissionCityDescription: '',
    companyEconomicActivityId: '',
    companyEconomicActivityDescription: '',
    companyEmissionHouseNumber: '',
    companyEmissionDistrictId: '',
    companyEmissionDistrictDescription: '',
    useElectronicNote: 0,
    publicCertificateEletronicNote: '',
    privateKeyEletronicNote: '',
    secretCodeEletronicNote: '',
    dateEletronicNote: null,
    ticketFontSize: 12,
    searchType: 0,
    salePrintMethod: 0,
    salePrintMethodPending: 0,
    noteAfterSale: true,
    noteAfterFinalizeSale: true,
    showOnlyNormalPaymentType: false,
    printPosition: null,
    notesQnt: 0,
    printHalfPage: false,
    showIdOnNote: false,
    showBarcodeOnNote: false,
    showBranchStock: false,
    hideWrittenTotal: false,
    showId: ShowIdEnum.DontShow,
    paymentScreen: PaymentScreen.Normal,
    hidePromissoryNote: false,
    useLogoInTicket: false,
    qntDecimals: 2,
    billAnonymousClient: false,
    isPixActivated: false,
    pixKeyType: null,
    pixKey: null,
    ticketType: TicketType.Complete,
    showSecondaryCoinTotalsTicket: false,
    productTicketType: ProductTicketType.Barcode,
    needToChoosePaymentMethod: true
}

const configsErrorDefault: ConfigsError = {
    companyName: '',
    companyDocument: '',
    balance: {
        initialDigit: '',
        barcodeLength: '',
        balanceLength: '',
        finalDigit: '',
    },
    companyRegime: '',
    companyEmissionDepartmentId: '',
    companyEmissionDepartmentDescription: '',
    companyEmissionCityId: '',
    companyEmissionCityDescription: '',
    companyEconomicActivityId: '',
    companyEconomicActivityDescription: '',
    companyEmissionHouseNumber: '',
    companyEmissionDistrictId: '',
    companyEmissionDistrictDescription: '',
    publicCertificateEletronicNote: '',
    privateKeyEletronicNote: '',
    secretCodeEletronicNote: '',
    dateEletronicNote: '',
    contacts: [],
    ticketFontSize: '',
}

const ConfigsContext = createContext<ConfigsContextInterface>({
    configs: configsDefault,
    setConfigs: () => {},
    configsError: configsErrorDefault,
    setConfigsError: () => {},
    validate: () => false,
    handleUpdateConfigs: () => Promise.resolve(),
    contactTypes: [],
    base64Logo: '',
})

export function useConfigs() {
    return useContext(ConfigsContext)
}

interface Props {
    children: ReactNode
}

export function ConfigsContextProvider({ children }: Props) {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [configs, setConfigs] = useState<ConfigsInfo>(configsDefault)
    const [contactTypes, setContactTypes] = useState<ContactTypeInfo[]>([])
    const [configsError, setConfigsError] =
        useState<ConfigsError>(configsErrorDefault)
    const [base64Logo, setBase64Logo] = useState('')

    useEffect(() => {
        handleLoadContactTypes()
        loadConfigs()
    }, [])

    const loadConfigs = async () => {
        loading.show()
        try {
            const res = await getConfigs()
            setConfigs(res)
            if (res.companyLogo) storeLogoInBase64(res.companyLogo.content)
        } catch (error) {
            snack.connectionFail(error)
        }
        loading.hide()
    }

    const storeLogoInBase64 = async (pathname: string) => {
        const res = await getFileInBase64(pathname)
        setBase64Logo(res)
    }

    const handleLoadContactTypes = async () => {
        loading.show()
        try {
            const res = await listContactTypes()
            setContactTypes(res)
        } catch (error) {
            snack.connectionFail(error)
        }
        loading.hide()
    }

    const validate = () => {
        let isOk = true
        const newConfigsError = JSON.parse(JSON.stringify(configsErrorDefault))

        const filteredContacts = configs.contacts.filter(
            (c) => c.value && c.contactType.id > 0
        )

        if (!configs.companyName) {
            isOk = false
            newConfigsError.companyName = 'Campo obrigatório'
        }
        if (!configs.companyDocument) {
            isOk = false
            newConfigsError.companyDocument = 'Campo obrigatório'
        }
        if (configs.companyDocument && !validateRuc(configs.companyDocument)) {
            isOk = false
            newConfigsError.companyDocument = 'Documento inválido'
        }
        if (!configs.companyRegime) {
            isOk = false
            newConfigsError.companyRegime = 'Campo obrigatório'
        }
        if (!configs.companyEmissionDepartmentId) {
            isOk = false
            newConfigsError.companyEmissionDepartmentId = 'Campo obrigatório'
        }
        if (!configs.companyEmissionDepartmentDescription) {
            isOk = false
            newConfigsError.companyEmissionDepartmentDescription =
                'Campo obrigatório'
        }
        if (!configs.companyEmissionCityId) {
            isOk = false
            newConfigsError.companyEmissionCityId = 'Campo obrigatório'
        }
        if (!configs.companyEmissionCityDescription) {
            isOk = false
            newConfigsError.companyEmissionCityDescription = 'Campo obrigatório'
        }
        if (!configs.companyEconomicActivityId) {
            isOk = false
            newConfigsError.companyEconomicActivityId = 'Campo obrigatório'
        }
        if (!configs.companyEconomicActivityDescription) {
            isOk = false
            newConfigsError.companyEconomicActivityDescription =
                'Campo obrigatório'
        }
        if (!configs.companyEmissionHouseNumber) {
            isOk = false
            newConfigsError.companyEmissionHouseNumber = 'Campo obrigatório'
        }
        if (!configs.companyEmissionDistrictId) {
            isOk = false
            newConfigsError.companyEmissionDistrictId = 'Campo obrigatório'
        }
        if (!configs.companyEmissionDistrictDescription) {
            isOk = false
            newConfigsError.companyEmissionDistrictDescription =
                'Campo obrigatório'
        }
        if (typeof configs.balance.initialDigit !== 'number') {
            isOk = false
            newConfigsError.balance.initialDigit = 'Campo obrigatório'
        }
        if (typeof configs.balance.barcodeLength !== 'number') {
            isOk = false
            newConfigsError.balance.barcodeLength = 'Campo obrigatório'
        }
        if (typeof configs.balance.balanceLength !== 'number') {
            isOk = false
            newConfigsError.balance.balanceLength = 'Campo obrigatório'
        }
        if (typeof configs.balance.finalDigit !== 'number') {
            isOk = false
            newConfigsError.balance.finalDigit = 'Campo obrigatório'
        }

        if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Address
            )
        ) {
            isOk = false
            snack.error('Pelo menos um endereço deve ser inserido')
        } else if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Phone
            )
        ) {
            isOk = false
            snack.error('Pelo menos um telefone deve ser inserido')
        } else if (
            !filteredContacts.find(
                (c) => c.contactType.id === ContactTypeEnum.Email
            )
        ) {
            isOk = false
            snack.error('Pelo menos um email deve ser inserido')
        }

        for (let i = 0; i < filteredContacts.length; i++) {
            if (
                filteredContacts[i].contactType.id === ContactTypeEnum.Email &&
                !validateEmail(filteredContacts[i].value)
            ) {
                isOk = false
                newConfigsError.contacts[i] = 'Email inválido'
            }
            if (
                filteredContacts[i].contactType.id === ContactTypeEnum.Phone &&
                filteredContacts[i].value.length > 15
            ) {
                isOk = false
                newConfigsError.contacts[i] =
                    'O telefone não pode ter mais de 15 caracteres'
            }
        }

        setConfigsError({ ...newConfigsError })
        return isOk
    }

    const handleUpdateConfigs = async () => {
        loading.show()
        try {
            await updateConfigs({
                fuso_horario: configs.timeZone,
                fuso_horario_pais: configs.timeZoneCountry,
                flag_horario_verao: configs.isSummerTime ? 1 : 0,
                senha_mestre: configs.masterPassword
                    ? configs.masterPassword
                    : null,
                metodo_venda: configs.saleMethod,
                nome_empresa: configs.companyName,
                logo_empresa: configs.companyLogo?.isBase64
                    ? configs.companyLogo.content
                    : null,
                slogan_empresa: configs.companySlogan,
                documento_empresa: configs.companyDocument,
                digito_inicial_balanca: configs.balance.initialDigit ?? 0,
                qnt_digitos_cod_barra_balanca:
                    configs.balance.barcodeLength ?? 0,
                qnt_digitos_balanca: configs.balance.balanceLength ?? 0,
                digito_final_balanca: configs.balance.finalDigit ?? 0,
                tipo_balanca: configs.balance.balanceType,
                contatos: configs.contacts
                    .filter((c) => c.value && c.contactType.id > 0)
                    .map((c) => ({
                        valor: c.value,
                        id_tipo_contato: c.contactType.id,
                    })),
                flag_usar_senha_mestre: Number(configs.useMasterPassword),
                regime_empresa: configs.companyRegime,
                cod_departamento_emissao_empresa:
                    configs.companyEmissionDepartmentId,
                desc_departamento_emissao_empresa:
                    configs.companyEmissionDepartmentDescription,
                cod_cidade_emissao_empresa: configs.companyEmissionCityId,
                desc_cidade_emissao_empresa:
                    configs.companyEmissionCityDescription,
                cod_atividade_economica_empresa:
                    configs.companyEconomicActivityId,
                desc_atividade_economica_empresa:
                    configs.companyEconomicActivityDescription,
                num_casa_emissao_empresa: configs.companyEmissionHouseNumber,
                cod_distrito_emissao_empresa: configs.companyEmissionDistrictId,
                desc_distrito_emissao_empresa:
                    configs.companyEmissionDistrictDescription,
                flag_fatura_eletronica: configs.useElectronicNote,
                certificado_publico_fatura:
                    configs.publicCertificateEletronicNote
                        ? configs.publicCertificateEletronicNote
                        : null,
                chave_privada_fatura: configs.privateKeyEletronicNote
                    ? configs.privateKeyEletronicNote
                    : null,
                codigo_secreto_fatura: configs.secretCodeEletronicNote
                    ? configs.secretCodeEletronicNote
                    : null,
                data_fatura: configs.dateEletronicNote
                    ? getDateInIsoFormat(configs.dateEletronicNote, true)
                    : null,
                flag_metodo_impressao: configs.printMethod,
                tamanho_fonte_ticket: configs.ticketFontSize,
                flag_tipo_buscador: configs.searchType,
                flag_impressao_venda: configs.salePrintMethod,
                flag_impressao_venda_pendente: configs.salePrintMethodPending,
                flag_fatura_apos_venda: Number(configs.noteAfterSale),
                flag_fatura_apos_finalizar_venda: Number(
                    configs.noteAfterFinalizeSale
                ),
                flag_exibir_contado: Number(configs.showOnlyNormalPaymentType),
                posicoes_fatura_impressa: configs.printPosition,
                quantidade_notas_fatura: configs.notesQnt,
                flag_imprimir_meia_folha: Number(configs.printHalfPage),
                flag_exibir_codigo_fatura: Number(configs.showIdOnNote),
                flag_exibir_codigo_barras_fatura: Number(
                    configs.showBarcodeOnNote
                ),
                flag_exibir_estoque_filiais: Number(configs.showBranchStock),
                flag_exibir_codigo: configs.showId,
                flag_esconder_total_letras: Number(configs.hideWrittenTotal),
                flag_tela_pagamento: configs.paymentScreen,
                flag_esconder_pagare: Number(configs.hidePromissoryNote),
                flag_logo_ticket: Number(configs.useLogoInTicket),
                decimais_quantidade: configs.qntDecimals,
                flag_faturacao_cliente_anonimo: Number(
                    configs.billAnonymousClient
                ),
                flag_pix_habilitado: Number(configs.isPixActivated),
                tipo_chave_pix: configs.pixKeyType,
                chave_pix: configs.pixKey,
                flag_formato_ticket: configs.ticketType,
                flag_exibir_moeda_secundaria_ticket: Number(
                    configs.showSecondaryCoinTotalsTicket
                ),
                flag_formato_ticket_produto: configs.productTicketType,
                flag_escolher_metodo_pagamento: Number(configs.needToChoosePaymentMethod)
            })
            loadConfigs()
            snack.success('Configurações atualizadas com sucesso!')
        } catch (error) {
            snack.connectionFail(error)
        }
        loading.hide()
    }

    return (
        <ConfigsContext.Provider
            value={{
                configs,
                setConfigs,
                configsError,
                setConfigsError,
                validate,
                handleUpdateConfigs,
                contactTypes,
                base64Logo,
            }}
        >
            {children}
        </ConfigsContext.Provider>
    )
}
