import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    styled,
} from '@mui/material'
import { Stack } from '@mui/system'
import QRCode from 'qrcode'
import { useEffect, useRef, useState } from 'react'
import { PaymentType } from '../../context/buy_context'
import { PrintMethodEnum, useConfigs } from '../../context/configs_context'
import { SaleInfo } from '../../context/sale_context'
import {
    getDateInDDMMYYYYFormat,
    getDateString,
    getNoteNameCharLimit,
    maskCurrency,
} from '../../utils/maks'
import { Rect } from '../PrintPositionModal'
import { NationalityEnum } from '../../context/supplier_context'
import { CoinInfo, useCoin } from '../../context/coin_context'
import { ClientDocumentType } from '../../context/client_context'

const writtenNumber = require('written-number')

const StyledTableCell = styled(TableCell)(({}) => ({
    color: 'text.primary',
    border: 'none',
    padding: '1px 2px',
    fontSize: '11px',
}))

interface LegalNoteProps {
    saleRaw: SaleInfo
    clientVia: boolean
    selectedCoin?: CoinInfo
}

const ContactIcon: Record<string, JSX.Element> = {
    Facebook: <FacebookIcon />,
    Instagram: <InstagramIcon />,
    Whatsapp: <WhatsAppIcon />,
    Endereço: <LocationOnIcon />,
    Telefone: <LocalPhoneIcon />,
    Email: <EmailIcon />,
}

const PRODUCT_THRESHOLD = 7

function LegalNote({ saleRaw, clientVia, selectedCoin }: LegalNoteProps) {
    const [sale, setSale] = useState<SaleInfo>(saleRaw)
    const { configs, contactTypes, base64Logo } = useConfigs()
    const qrCodeRef = useRef<HTMLCanvasElement | null>(null)

    const twoPages =
        sale.products.length > PRODUCT_THRESHOLD ||
        sale.qrCode ||
        !configs.printHalfPage

    const contacts = sale.branch ? sale.branch.contacts : configs.contacts

    const totals =
        selectedCoin?.id !== sale.coin?.id
            ? sale.totalSecondaryCoin?.find(
                  (v) => v.coinId === selectedCoin?.id
              )!
            : sale

    useEffect(() => {
        const newSale = { ...saleRaw }

        if (newSale.qrCode)
            QRCode.toCanvas(qrCodeRef.current, newSale.qrCode, { width: 200 })

        if (newSale.client?.nationality === NationalityEnum.Foregein) {
            newSale.products.forEach((p) => {
                p.ivaValue = 0
            })
            newSale.totalIva = 0
            newSale.totalIvaFivePercent = 0
            newSale.totalIvaTenPercent = 0
            if (
                typeof newSale.totalExcept === 'number' &&
                typeof newSale.totalFivePercent === 'number' &&
                typeof newSale.totalTenPercent === 'number'
            )
                newSale.totalExcept +=
                    newSale.totalFivePercent + newSale.totalTenPercent
            newSale.totalFivePercent = 0
            newSale.totalTenPercent = 0

            if (newSale.totalSecondaryCoin) {
                for (const totalSecondaryCoin of newSale.totalSecondaryCoin) {
                    totalSecondaryCoin.totalIva = 0
                    totalSecondaryCoin.totalIvaFivePercent = 0
                    totalSecondaryCoin.totalIvaTenPercent = 0
                    if (
                        typeof totalSecondaryCoin.totalExcept === 'number' &&
                        typeof totalSecondaryCoin.totalFivePercent ===
                            'number' &&
                        typeof totalSecondaryCoin.totalTenPercent === 'number'
                    )
                        totalSecondaryCoin.totalExcept +=
                            totalSecondaryCoin.totalFivePercent +
                            totalSecondaryCoin.totalTenPercent
                    totalSecondaryCoin.totalFivePercent = 0
                    totalSecondaryCoin.totalTenPercent = 0
                }
            }
        }

        setSale(newSale)
    }, [saleRaw])

    if (
        configs.printMethod === PrintMethodEnum.DataOnly &&
        configs.printPosition
    ) {
        const boxes = JSON.parse(configs.printPosition) as Rect[]

        setTimeout(() => {
            const dataPrint = document.getElementById(
                `data-print-${sale.id}-${clientVia ? 'cl' : 'or'}`
            )
            if (!dataPrint) return

            dataPrint.innerHTML = ''

            const fontSize = '14px'
            const fontFamily = 'sans-serif'

            boxes.forEach((box) => {
                const el = document.createElement('div')
                el.style.position = 'absolute'
                el.style.left = `${box.xProp * 100}%`
                el.style.top = `${box.yProp * 100}%`
                el.style.fontSize = fontSize
                el.style.fontFamily = fontFamily

                if (box.text === 'código') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = product.product.id?.toString() ?? ''
                        el.appendChild(p)
                    }
                }

                if (box.text === 'cod bar') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = product.product.barcodes[0]
                        el.appendChild(p)
                    }
                }

                if (box.text === 'desc') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = product.product.name.slice(0, 30)
                        el.appendChild(p)
                    }
                }

                if (box.text === 'qnt') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = product.qnt.toString()
                        el.appendChild(p)
                    }
                }

                if (box.text === 'p un') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = maskCurrency(product.price, sale.coin)
                        el.appendChild(p)
                    }
                }

                if (box.text === 'p ex') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = maskCurrency(
                            product.ivaValue === 0 ? product.price : 0,
                            sale.coin
                        )
                        el.appendChild(p)
                    }
                }

                if (box.text === 'p 5%') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = maskCurrency(
                            product.ivaValue === 5 ? product.price : 0,
                            sale.coin
                        )
                        el.appendChild(p)
                    }
                }

                if (box.text === 'p 10%') {
                    el.style.display = 'flex'
                    el.style.flexDirection = 'column'
                    el.style.gap = '5px'

                    for (const product of sale.products) {
                        const p = document.createElement('p')
                        p.style.fontSize = fontSize
                        p.style.fontFamily = fontFamily
                        p.innerHTML = maskCurrency(
                            product.ivaValue === 10 ? product.price : 0,
                            sale.coin
                        )
                        el.appendChild(p)
                    }
                }

                if (box.text === 'nome cl') {
                    el.innerHTML = sale.client?.name ?? ''
                }

                if (box.text === 'doc cl') {
                    el.innerHTML = sale.client?.document ?? ''
                }

                if (box.text === 'dir cl') {
                    el.innerHTML = sale.client?.address ?? ''
                }

                if (box.text === 'cond') {
                    el.innerHTML =
                        (sale.paymentTypeShow === PaymentType.Credit ||
                            sale.paymentTypeShow === PaymentType.Parcel) &&
                        !configs.showOnlyNormalPaymentType
                            ? 'CRÉDITO'
                            : 'CONTADO'
                }

                if (box.text === 'data') {
                    el.style.display = 'flex'
                    el.style.gap = '120px'

                    const date = getDateString({
                        date: sale.noteDate,
                        hour: sale.noteHour,
                        configs: configs,
                        isSummerTime: !!sale.isNoteSummerTime,
                    })

                    const meses = [
                        'ENERO',
                        'FEBRERO',
                        'MARZO',
                        'ABRIL',
                        'MAYO',
                        'JUNIO',
                        'JULIO',
                        'AGOSTO',
                        'SEPTIEMBRE',
                        'OCTUBRE',
                        'NOVIEMBRE',
                        'DICIEMBRE',
                    ]

                    const [dd, mm, yy] = date.split(' ')[0].split('/')

                    const ddEl = document.createElement('p')
                    ddEl.style.fontSize = fontSize
                    ddEl.style.fontFamily = fontFamily
                    ddEl.innerHTML = dd

                    const mmEl = document.createElement('p')
                    mmEl.style.fontSize = fontSize
                    mmEl.style.fontFamily = fontFamily
                    mmEl.innerHTML = meses[Number(mm) - 1]

                    const yyEl = document.createElement('p')
                    yyEl.style.fontSize = fontSize
                    yyEl.style.fontFamily = fontFamily
                    yyEl.innerHTML = yy

                    el.appendChild(ddEl)
                    el.appendChild(mmEl)
                    el.appendChild(yyEl)
                }

                if (box.text === 'sub ex') {
                    el.innerHTML = maskCurrency(
                        sale.totalExcept ?? 0,
                        sale.coin
                    )
                }

                if (box.text === 'sub 5%') {
                    el.innerHTML = maskCurrency(
                        sale.totalFivePercent ?? 0,
                        sale.coin
                    )
                }

                if (box.text === 'sub 10%') {
                    el.innerHTML = maskCurrency(
                        sale.totalTenPercent ?? 0,
                        sale.coin
                    )
                }

                if (box.text === 'iva 5%') {
                    el.innerHTML = maskCurrency(
                        sale.totalIvaFivePercent ?? 0,
                        sale.coin
                    )
                }

                if (box.text === 'iva 10%') {
                    el.innerHTML = maskCurrency(
                        sale.totalIvaTenPercent ?? 0,
                        sale.coin
                    )
                }

                if (box.text === 'iva tot') {
                    el.innerHTML = maskCurrency(sale.totalIva ?? 0, sale.coin)
                }

                if (box.text === 'total ext') {
                    el.innerHTML = writtenNumber(sale.total ?? 0, {
                        lang: 'es',
                    })
                }

                if (box.text === 'total') {
                    el.style.fontWeight = 'bold'
                    el.innerHTML = maskCurrency(sale.total ?? 0, sale.coin)
                }

                dataPrint.appendChild(el)
            })
        }, 100)

        return (
            <Stack
                direction="column"
                width="100%"
                px="20px"
                py="10px"
                height={'100vh'}
                id={`data-print-${sale.id}-${clientVia ? 'cl' : 'or'}`}
                position={'relative'}
                /* sx={{ transform: 'rotate(90deg)' }} */
            ></Stack>
        )
    }

    const totalDiscount = sale.products?.reduce((total, product) => {
        const values =
            selectedCoin?.id !== sale.coin?.id
                ? product.valueSecondaryCoin?.find(
                      (v) => v.coinId === selectedCoin?.id
                  )!
                : product

        if (!values.unitPrice) return total

        if (values.unitPrice < values.price) return total

        return total + (values.unitPrice - values.price) * product.qnt
    }, 0)

    const charNameLimit = getNoteNameCharLimit(configs)

    return (
        <Stack
            direction="column"
            width="100%"
            px="20px"
            py="10px"
            height={twoPages ? '100vh' : '50vh'}
        >
            <Stack direction={'row'} width={'100%'}>
                <Stack
                    direction={'column'}
                    border={'1px solid black'}
                    borderRight={'0'}
                    borderRadius={'12px'}
                    width={'70%'}
                >
                    <Stack direction={'row'} p={'10px'}>
                        <Box
                            component={'img'}
                            src={base64Logo}
                            width={'220px'}
                            height={'110px'}
                            zIndex={'-1'}
                        />
                        <Stack textAlign={'center'} flex={'1'} px={'15px'}>
                            <Typography
                                fontSize={'14px'}
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {configs.companyName}
                            </Typography>
                            <Typography
                                fontSize={'9px'}
                                sx={{ wordBreak: 'break-word' }}
                                fontStyle={'italic'}
                                whiteSpace={'pre-line'}
                            >
                                {`${configs.companySlogan}`}
                            </Typography>
                            <Typography
                                fontSize={'8px'}
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {contactTypes
                                    .filter((ct) =>
                                        contacts.find(
                                            (c) => c.contactTypeId === ct.id
                                        )
                                    )
                                    .map(
                                        (ct) =>
                                            `${ct.name}: ${contacts
                                                .filter(
                                                    (c) =>
                                                        c.contactTypeId ===
                                                        ct.id
                                                )
                                                .map((c) => c.value)
                                                .join(', ')}`
                                    )
                                    .join('; ')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction={'column'}
                    border={'1px solid black'}
                    borderRadius={'12px'}
                    width={'30%'}
                >
                    <Stack direction={'row'} p={'10px'}>
                        <Box flex={'1'}>
                            <Typography fontSize={'11px'}>
                                TIMBRADO N {sale.note?.timbrado}
                            </Typography>
                            <Typography fontSize={'11px'} mt={'1px'}>
                                RUC: {configs.companyDocument}
                            </Typography>
                            <Typography fontSize={'11px'} mt={'1px'}>
                                Fecha início vigência:{' '}
                                {getDateInDDMMYYYYFormat(
                                    sale.note?.iniD as any
                                )}
                            </Typography>
                            <Typography fontSize={'11px'}>
                                Fecha fin vigência:{' '}
                                {sale.note?.endD
                                    ? getDateInDDMMYYYYFormat(sale.note.endD)
                                    : '---'}
                            </Typography>
                            <Typography
                                fontSize={'14px'}
                                mt={'10px'}
                                fontWeight={'bold'}
                                textAlign={'center'}
                            >
                                {sale.bill}
                            </Typography>
                            <Typography
                                fontSize={'16px'}
                                fontWeight={'bold'}
                                textAlign={'center'}
                            >
                                {sale.qrCode ? 'FACTURA ELETRÔNICA' : 'FACTURA'}
                            </Typography>
                        </Box>
                        {/* <Typography
                            sx={{
                                writingMode: 'vertical-rl',
                                textOrientation: 'mixed',
                            }}
                            p={'30px'}
                            color={'error'}
                            fontSize={'26px'}
                        >
                            {sale.bill}
                        </Typography> */}
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                direction={'column'}
                padding={'5px'}
            >
                <Stack direction={'row'} alignItems={'center'}>
                    <Box width={'70%'}>
                        <Typography fontSize={'16px'} fontWeight={'bold'}>
                            Nombre: {sale.client?.name || '---'}
                        </Typography>
                        <Typography fontSize={'16px'} fontWeight={'bold'}>
                            {sale.client?.documentType ===
                            ClientDocumentType.RUC
                                ? 'Ruc'
                                : 'Documento'}
                            : {sale.client?.document || '---'}
                        </Typography>
                    </Box>
                    <Box>
                        <Box>
                            <Typography fontSize={'11px'}>
                                Cond. de venta:{' '}
                                {(sale.paymentTypeShow === PaymentType.Credit ||
                                    sale.paymentTypeShow ===
                                        PaymentType.Parcel) &&
                                !configs.showOnlyNormalPaymentType
                                    ? 'CRÉDITO'
                                    : 'CONTADO'}
                            </Typography>
                            <Typography fontSize={'11px'}>
                                Fecha:{' '}
                                {getDateString({
                                    date: sale.noteDate,
                                    hour: sale.noteHour,
                                    configs: configs,
                                    isSummerTime: !!sale.isNoteSummerTime,
                                })}
                            </Typography>
                            <Typography fontSize={'11px'}>
                                Telefonos:{' '}
                                {sale.client?.phoneNumbers.join(', ') || '---'}
                            </Typography>
                            <Typography fontSize={'11px'}>
                                Dirección: {sale.client?.address || '---'}
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                direction={'column'}
                flex={'1'}
                justifyContent={'space-between'}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {configs.showIdOnNote && (
                                <StyledTableCell
                                    sx={{
                                        borderBottom: '1px solid black',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    CÓDIGO
                                </StyledTableCell>
                            )}
                            {configs.showBarcodeOnNote && (
                                <StyledTableCell
                                    sx={{
                                        borderBottom: '1px solid black',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    CÓDIGO BAR.
                                </StyledTableCell>
                            )}
                            <StyledTableCell
                                sx={{
                                    borderBottom: '1px solid black',
                                    fontWeight: 'bold',
                                }}
                            >
                                CANT. / DESC.
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    borderBottom: '1px solid black',
                                    fontWeight: 'bold',
                                }}
                            >
                                P. UNITÁRIO
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    borderBottom: '1px solid black',
                                    fontWeight: 'bold',
                                }}
                            >
                                EXENTO
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    borderBottom: '1px solid black',
                                    fontWeight: 'bold',
                                }}
                            >
                                5%
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    borderRight: 'none',
                                    borderBottom: '1px solid black',
                                    fontWeight: 'bold',
                                }}
                            >
                                10%
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sale.products.map((product) => {
                            const values =
                                selectedCoin?.id !== sale.coin?.id
                                    ? product.valueSecondaryCoin?.find(
                                          (v) => v.coinId === selectedCoin?.id
                                      )!
                                    : product

                            const subtotal = values?.price * product.qnt

                            return (
                                <TableRow sx={{ height: '0' }}>
                                    {configs.showIdOnNote && (
                                        <StyledTableCell>
                                            {product.product.internalCodes[0]}
                                        </StyledTableCell>
                                    )}
                                    {configs.showBarcodeOnNote && (
                                        <StyledTableCell>
                                            {product.product.barcodes[0] ||
                                                '---'}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell>
                                        {product.qnt} /{' '}
                                        <Box
                                            component={'span'}
                                            fontSize={'9px'}
                                        >
                                            {product.product.name.length >
                                            charNameLimit
                                                ? product.product.name.slice(
                                                      0,
                                                      charNameLimit
                                                  ) + '...'
                                                : product.product.name}
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {maskCurrency(
                                            values?.price,
                                            selectedCoin
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {maskCurrency(
                                            product.ivaValue === 0
                                                ? subtotal
                                                : 0,
                                            selectedCoin
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {maskCurrency(
                                            product.ivaValue === 5
                                                ? subtotal
                                                : 0,
                                            selectedCoin
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{ borderRight: 'none' }}
                                    >
                                        {maskCurrency(
                                            product.ivaValue === 10
                                                ? subtotal
                                                : 0,
                                            selectedCoin
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <Table>
                    <TableRow>
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                                fontSize: '14px',
                            }}
                        >
                            Subtotales:
                        </StyledTableCell>
                        {configs.showIdOnNote && (
                            <StyledTableCell
                                sx={{
                                    borderTop: '1px solid black',
                                }}
                            ></StyledTableCell>
                        )}
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                            }}
                        ></StyledTableCell>
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                            }}
                        ></StyledTableCell>
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                            }}
                        >
                            {maskCurrency(
                                totals?.totalExcept as number,
                                selectedCoin
                            )}
                        </StyledTableCell>
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                            }}
                        >
                            {maskCurrency(
                                totals?.totalFivePercent as number,
                                selectedCoin
                            )}
                        </StyledTableCell>
                        <StyledTableCell
                            sx={{
                                borderTop: '1px solid black',
                            }}
                        >
                            {maskCurrency(
                                totals?.totalTenPercent as number,
                                selectedCoin
                            )}
                        </StyledTableCell>
                    </TableRow>
                </Table>
            </Stack>
            <Stack
                width={'100%'}
                border={'1px solid black'}
                borderTop={'0'}
                borderRadius={'12px'}
                pl={'10px'}
                pb={'5px'}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack>
                        <Typography fontSize={'12px'}>
                            Total a pagar:{' '}
                            <Box component={'span'} fontSize={'11px'}>
                                (En letras)
                            </Box>{' '}
                            {writtenNumber(totals?.total, {
                                lang: 'es',
                            })}{' '}
                            {selectedCoin?.name.toLowerCase() +
                                (totals?.total !== 1 ? 'es' : '')}
                        </Typography>
                        {totalDiscount > 0 && (
                            <Typography fontSize={'12px'}>
                                Descuento:{' '}
                                {maskCurrency(totalDiscount, selectedCoin)}
                            </Typography>
                        )}
                    </Stack>
                    <Typography
                        fontSize={'12px'}
                        borderLeft={'1px solid black'}
                        borderBottom={'1px solid black'}
                        p={'5px'}
                    >
                        {maskCurrency(totals?.total, selectedCoin)}
                    </Typography>
                </Stack>
                <Stack direction={'row'} spacing={15} mt={'3px'}>
                    <Typography sx={{ fontSize: '12px' }}>
                        Liquidación del IVA:{' '}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                        (5%):{' '}
                        {maskCurrency(
                            totals?.totalIvaFivePercent as number,
                            selectedCoin
                        )}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                        (10%):{' '}
                        {maskCurrency(
                            totals?.totalIvaTenPercent as number,
                            selectedCoin
                        )}
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                        (T. IVA):{' '}
                        {maskCurrency(totals?.totalIva as number, selectedCoin)}
                    </Typography>
                </Stack>
                <Typography mt={'3px'} fontSize={'12px'}>
                    {clientVia ? 'Original: Cliente' : 'Duplicado: Tributário'}
                </Typography>
                {sale.observation && !sale.isJoinedNote && (
                    <Typography mt={'3px'} fontSize={'12px'}>
                        Nota: {sale.observation}
                    </Typography>
                )}
            </Stack>
            {sale.qrCode && (
                <Stack
                    width={'100%'}
                    border={'1px solid black'}
                    borderTop={'0'}
                    borderRadius={'12px'}
                    p="10px"
                >
                    <Stack direction={'row'} spacing={'15px'}>
                        <Box component="canvas" ref={qrCodeRef} />
                        <Stack direction="column" py="10px" spacing={'15px'}>
                            <Typography fontSize="12px">
                                Consulte la validez de esta factura eletrónica
                                con el documento CDC impreso en:
                            </Typography>
                            <Typography fontSize="13px">
                                https://ekuatia.set.gov.py/consultas/
                            </Typography>
                            <Typography fontSize="13px">
                                CDC: {sale.cdc}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography fontSize="12px" mt="5px">
                        ESTE DOCUMENTO ES UNA REPRESENTACIÓN GRÁFICA DE UN
                        DOCUMENTO ELETRÓNICO (XML)
                    </Typography>
                    <Typography my="10px" fontWeight="bold" fontSize="12px">
                        Información de interés del facturador eletrónico emisor
                    </Typography>
                    <Typography fontSize="11px">
                        Si su documento eletrónico presenta algún error, podrá
                        solicitar la modifcación dentro de las 72 horas
                        siguientes de la emisión de éste comprobante
                    </Typography>
                </Stack>
            )}
            {!sale.qrCode && (
                <Stack
                    width={'100%'}
                    border={'1px solid black'}
                    borderTop={'0'}
                    borderRadius={'12px'}
                    p="10px"
                    pt="30px"
                    justifyContent={'center'}
                    direction={'row'}
                    spacing={'60px'}
                >
                    <Stack
                        alignItems={'center'}
                        borderTop={'1px solid black'}
                        pt={'2px'}
                        px={'30px'}
                    >
                        <Typography fontSize="11px">
                            Firma del cliente
                        </Typography>
                    </Stack>
                    <Stack
                        alignItems={'center'}
                        borderTop={'1px solid black'}
                        pt={'2px'}
                        px={'30px'}
                    >
                        <Typography fontSize="11px">
                            Aclaración de firma
                        </Typography>
                    </Stack>
                    <Typography fontSize="11px">Fecha:</Typography>
                </Stack>
            )}
        </Stack>
    )
}

interface Props {
    sales: SaleInfo[]
    handleClose(): void
    selectedCoin?: CoinInfo
    dontPrint?: boolean
}

export function LegalNoteDialog({
    handleClose,
    sales,
    selectedCoin,
    dontPrint,
}: Props) {
    const { configs } = useConfigs()

    useEffect(() => {
        if (!dontPrint)
            setTimeout(() => {
                window.scroll(0, 0)
                window.print()
                handleClose()
            }, 100)
    }, [])

    return (
        <Box className={!dontPrint ? 'printable' : undefined} id="note">
            <Stack direction={'column'}>
                {sales.map((sale) => {
                    return (
                        <>
                            {Array.from({
                                length: !dontPrint ? configs.notesQnt : 1,
                            }).map((_, index) => (
                                <LegalNote
                                    saleRaw={sale}
                                    clientVia={index % 2 === 0}
                                    selectedCoin={selectedCoin ?? sale.coin}
                                />
                            ))}
                        </>
                    )
                })}
            </Stack>
        </Box>
    )
}
