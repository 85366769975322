import MenuIcon from '@mui/icons-material/Menu'
import {
    AppBar,
    Avatar,
    Breadcrumbs,
    Button,
    Divider,
    IconButton,
    Link,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccount } from '../../context/account_context'
import { useConfigs } from '../../context/configs_context'
import { doLogout } from '../../services/auth'
import { Navigation, navMenu } from '../LeftPanel/nav'
import SellIcon from '@mui/icons-material/Sell'
import { deepOrange } from '@mui/material/colors'
import { Logout } from '@mui/icons-material'
import { useCashier } from '../../context/cashier_context'

interface Props {
    drawerWidth: number
    headerHeight: number
    setShowMobile(value: boolean): void
    hideSideBar: boolean
}

const Header = ({
    drawerWidth,
    setShowMobile,
    headerHeight,
    hideSideBar,
}: Props) => {
    const appBarRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { accountInfo } = useAccount()
    const [nav, setNav] = useState<Navigation | null>(null)
    const [subnav, setSubNav] = useState<Navigation | null>(null)
    const { configs } = useConfigs()
    const { loggedCashier } = useCashier()
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)

    const logout = () => {
        doLogout()
        navigate('/login')
    }

    useEffect(() => {
        navMenu.forEach((nav) => {
            const subnav = nav.subitems?.find(
                (subnav) => subnav.link === pathname.replace(/\/\d+$/, '')
            )
            if (subnav) {
                setNav(nav)
                setSubNav(subnav)
            }
        })
    }, [pathname])

    return (
        <>
            <Menu
                open={Boolean(anchor)}
                onClose={() => {
                    setAnchor(null)
                }}
                anchorEl={anchor}
                sx={{
                    top: '10px',
                }}
            >
                <MenuItem>Olá, {accountInfo?.name}</MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Salir
                </MenuItem>
            </Menu>
            <AppBar
                position="sticky"
                sx={{
                    width: {
                        xs: '100%',
                        lg: hideSideBar
                            ? '100%'
                            : `calc(100% - ${drawerWidth}px)`,
                    },
                    ml: { xs: '0', lg: hideSideBar ? '0' : `${drawerWidth}px` },
                    background: '#FFF',
                }}
                ref={appBarRef}
                elevation={0}
                className="header"
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        height: `${headerHeight}px`,
                        alignItems: 'center',
                        pr: '30px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box display={'flex'} alignItems={'center'}>
                        <IconButton
                            edge="start"
                            onClick={() => setShowMobile(true)}
                            sx={{
                                ml: '10px',
                                display: {
                                    xs: 'block',
                                    lg: hideSideBar ? 'block' : 'none',
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Stack sx={{ ml: '30px' }}>
                            <Typography color="text.primary" fontWeight="500">
                                {configs.companyName} - {loggedCashier?.name}
                            </Typography>
                            <Breadcrumbs>
                                <Link
                                    underline="hover"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    color="inherit"
                                    href={nav?.link}
                                >
                                    {/* <Box mr={0.5}>{nav?.icon}</Box> */}
                                    {nav?.text}
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    color="inherit"
                                    href={nav?.link}
                                >
                                    {/* <Box mr={0.5}>{subnav?.icon}</Box> */}
                                    {subnav?.text}
                                </Link>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    {/* <Box
                    component="img"
                    src="/logo.jpg"
                    sx={{ padding: '20px', height: '90px' }}
                /> */}
                    <Box
                        sx={{
                            color: 'black',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '30px',
                        }}
                    >
                        <Button
                            color="success"
                            variant="contained"
                            startIcon={<SellIcon />}
                            sx={{
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                navigate('/system/sale/new')
                            }}
                        >
                            Vender ahora
                        </Button>
                        <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
                            <Avatar
                                sx={{
                                    bgcolor: deepOrange[500],
                                    cursor: 'pointer',
                                }}
                            >
                                {accountInfo?.name[0]}
                            </Avatar>
                        </IconButton>
                    </Box>
                </Box>
            </AppBar>
        </>
    )
}

export default Header
