import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { listPaymentMethods } from '../services/payment'
import {
    CashierClosedSaleError,
    CreatePreSaleInterface,
    ParaguayTransfer,
    Pix,
    createPreSale,
    createSale,
    getPreSaleById,
    getSaleById,
    updatePreSale,
    updateSale,
} from '../services/sale'
import { AccountInfo, useAccount } from './account_context'
import { BuyInfo, PaymentType } from './buy_context'
import { CashierInfo } from './cashier_context'
import {
    ClientDocumentType,
    ClientInfo,
    anonymousClient,
} from './client_context'
import { CoinInfo, useCoin } from './coin_context'
import { DiscountInfo, useDiscount } from './discount_context'
import { useLoading } from './loading_context'
import { IvaEnum, ProductInfo } from './product_context'
import { useSnack } from './snack_context'
import { PaymentMethodInfo } from './payment_method_context'
import { BranchInfo } from './branch_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { NoteInfo } from './notes_context'
import { usePassword } from './password_context'
import { validateMasterPassword } from '../services/configs'
import { EmployeeInfo } from '../pages/Employee/Home'
import { maskCurrency } from '../utils/maks'
import { NewPayment } from '../components/PaymentModal'
import { NationalityEnum, SupplierInfo } from './supplier_context'
const currency = require('currency.js')

export interface ProductInSale {
    id: number
    product: ProductInfo
    price: number
    qnt: number
    subtotal: number
    discount: number
    systemDiscount: Partial<DiscountInfo> | null
    buyPrice?: number
    ivaValue?: number
    selectedNamedPrice?: string
    unitPrice?: number
    date?: string
    hour?: string
    isSummerTime?: boolean
    prevSaleId?: number
    prevSale?: SaleInfo
    valueSecondaryCoin?: {
        iso: string
        coinId: number
        price: number
        buyPrice: number
        unitPrice: number
    }[]
}

export interface PaymentInSale {
    id?: number
    paymentCoin: CoinInfo | null
    changeCoin: CoinInfo | null
    amountPayed: number
    discount: number
    paymentMethod: PaymentMethodInfo | null
    madeBy: AccountInfo | null
    date?: string
    hour?: string
    cashier?: CashierInfo
    exchange?: {
        exchangeValue: number
        exchangeAmountPayed: number
        flagReverseExchange: number
    }
    proofText?: string
    proofImage?: string
    isSummerTime?: boolean
    exchangeChange: number | null
    change: number
    bill?: string
    note?: NoteInfo | null
    noteDate?: string
    noteHour?: string
    isNoteSummerTime?: boolean
    paymentStatus?: number
    employee?: EmployeeInfo
    sale?: SaleInfo
    isJoinedPayment?: boolean
    prevPayments?: PaymentInSale[]
}

export enum SaleStatus {
    Pending = 0,
    Open = 1,
    Closed = 2,
    Canceled = 3,
    Expired = 4,
}

export enum SalePaymentStatus {
    Pending = 0,
    Created = 1,
    Canceled = 2,
}

export const SaleStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Abierta',
    2: 'Cerrada',
    3: 'Cancelada',
    4: 'Expirada',
}

export const SalePaymentStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Pago',
    2: 'Cancelado',
}

export interface SaleInfo {
    id?: number
    bill: string | null
    products: ProductInSale[]
    selectedProducts?: ProductInSale[]
    prevEditingProducts?: ProductInSale[]
    client: ClientInfo | null
    totalIva: number
    total: number
    totalAmoutPayed: number
    totalFees: number
    payments: PaymentInSale[]
    observation: string
    paymentType: number | null
    paymentTypeShow: number | null
    saleStatus: number
    initialFees: number
    note: NoteInfo | null
    branch: BranchInfo | null
    date?: string
    hour?: string
    expDate?: string
    expHour?: string
    noteDate?: string
    noteHour?: string
    isNoteSummerTime?: boolean
    coin?: CoinInfo
    cashier?: CashierInfo
    account?: AccountInfo
    productCount?: number
    isSummerTime?: boolean
    preSale?: PreSaleInfo
    totalIvaFivePercent?: number
    totalIvaTenPercent?: number
    totalExcept?: number
    totalFivePercent?: number
    totalTenPercent?: number
    cdc?: string
    qrCode?: string
    employee?: EmployeeInfo
    statusEletronicNote?: number
    logEletronicNote?: string
    isEditing?: boolean
    creditNotes?: CreditNoteInfo[]
    isJoinedNote?: boolean
    branchId?: number
    totalSecondaryCoin?: {
        exchange: number
        iso: string
        coinId: number
        totalIvaFivePercent: number
        totalIvaTenPercent: number
        totalIva: number
        totalExcept: number
        totalFivePercent: number
        totalTenPercent: number
        total: number
    }[]
    guidePercentage?: number
}

export interface ProductInPreSale {
    id: number
    product: ProductInfo
    price: number
    qnt: number
    subtotal: number
    discount: number
    selectedNamedPrice?: string
}

export interface PreSaleInfo {
    id?: number
    title: string
    products: ProductInPreSale[]
    branch: BranchInfo | null
    totalIva: number
    total: number
    annotation: string | null
    client: ClientInfo | null
    date?: string
    hour?: string
    cashier?: CashierInfo
    account?: AccountInfo
    productCount?: number
    isSummerTime?: boolean
    isActive?: boolean
    isEditing?: boolean
}

export interface SaleReportsInfo {
    coinId: number
    totalSold: number
    totalFees: number
    totalPaid: number
    totalCost: number
    totalGain: number
    totalInCreditNotes: number
    totalDebt: number
    totalSales: number
    coin?: CoinInfo
}

export interface SoldProductsInfo {
    productId: number
    product: string
    averageBuyPrice: number
    averageSalePrice: number
    qntSold: number
    qntSales: number
    coin?: CoinInfo
}

export interface ParcelInfo {
    id: number
    total: number
    totalPaid: number
    totalFees: number
    endDate: string
    endHour: string
    status: number
    saleId: number
    payments: PaymentInSale[]
    parcelNumber: number
}

export interface ProductInCreditNote {
    id?: number
    qnt: number
    price: number
    unitPrice: number
    ivaValue: number
    subtotal: number
    product: ProductInfo
}

export interface CreditNoteInfo {
    id?: number
    bill: string | null
    products: ProductInCreditNote[]
    totalIva: number
    total: number
    note: NoteInfo | null
    date?: string
    hour?: string
    isSummerTime: boolean
    type: number
    cashier?: Partial<CashierInfo>
    account?: Partial<AccountInfo>
    totalIvaFivePercent?: number
    totalIvaTenPercent?: number
    totalExcept?: number
    totalFivePercent?: number
    totalTenPercent?: number
    cdc?: string
    qrCode?: string
    statusEletronicNote?: number
    logEletronicNote?: string
    noteDate?: string
    noteHour?: string
    isNoteSummerTime?: boolean
    operations?: {
        sale?: SaleInfo
        buy?: BuyInfo
    }[]
    coin?: CoinInfo
    branch?: BranchInfo
    client?: ClientInfo
    supplier?: SupplierInfo
}

export enum CreditNoteType {
    SpecificSale = 0,
    GeneralSale = 1,
    SpecificPurchase = 2,
    GeneralPurchase = 3,
}

export const CreditNoteTypeLabel: Record<number, string> = {
    0: 'Venta específica',
    1: 'Venta general',
    2: 'Compra específica',
    3: 'Compra general',
}

export enum EletronicNoteStatus {
    NoEletronicNote = 0,
    PendingConfirmation = 1,
    Confirmed = 2,
    Error = 3,
}

export const EletronicNoteStatusLabel: Record<number, string> = {
    0: 'Sin',
    1: 'Confirmación pendiente',
    2: 'Envio confirmado',
    3: 'Error',
}

export enum ParcelStatus {
    Pending = 0,
    Paid = 1,
    Expired = 2,
    Canceled = 3,
}

export const ParcelStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Pago',
    2: 'Expirada',
    3: 'Cancelada',
}

const saleContextDefault: SaleInfo = {
    bill: '',
    products: [],
    client: anonymousClient,
    totalIva: 0,
    total: 0,
    totalAmoutPayed: 0,
    totalFees: 0,
    initialFees: 0,
    observation: '',
    paymentType: null,
    paymentTypeShow: null,
    saleStatus: SaleStatus.Open,
    payments: [],
    note: null,
    branch: null,
    isEditing: false,
    prevEditingProducts: undefined,
}

const preSaleContextDefault: PreSaleInfo = {
    title: '',
    products: [],
    branch: null,
    total: 0,
    totalIva: 0,
    annotation: null,
    client: anonymousClient,
}

export const getClientLabel = (client: ClientInfo) => {
    if (client.id === 0) return client.name
    return `${client.name}, email: ${client.email}, ${
        client?.documentType === ClientDocumentType.RUC ? 'ruc' : 'documento'
    }: ${client.document}, crédito: ${client.allowCredit ? 'sim' : 'não'}`
}

interface HandleCreateNewSaleInterface {
    paymentType?: number
    payments?: NewPayment[]
    useLegalNote?: boolean
    initialFees?: number
    parcels?: number[]
    discount?: number
    changeCoin?: CoinInfo
    employeeId?: number
    noteCoin?: CoinInfo
    isPix?: boolean
    isParaguayTransfer?: boolean
}

export interface HandleCreateNewSaleResponse {
    sale: SaleInfo | null
    pix: Pix | null
    paraguayTransfer: ParaguayTransfer | null
}

export interface SaleContextInterface {
    saleInfo: SaleInfo
    setSaleInfo: Dispatch<SetStateAction<SaleInfo>>
    preSaleInfo: PreSaleInfo
    setPreSaleInfo: Dispatch<SetStateAction<PreSaleInfo>>
    handleChangeBill(bill: string): void
    handleAddNewProducts(products: ProductInfo[]): void
    handleLoadPreSale(preSale: PreSaleInfo): void
    handleRemoveProduct(product: ProductInSale, pass?: string): void
    handleChangeProductQnt(product: ProductInSale, qnt: number): void
    handleChangeProductPrice(product: ProductInSale, price: number): void
    handleChangeProductDiscount(product: ProductInSale, discount: number): void
    handleChangeProductSelectedNamedPrice(
        product: ProductInSale,
        selectedNamedPrice?: string
    ): void
    handleChangeClient(client: ClientInfo | null): void
    handleChangePayment(paymentType: number, payment: PaymentInSale): void
    handleChangeObservation(o: string): void
    handleAddNewProductsInPreSale(products: ProductInfo[]): void
    handleRemoveProductInPreSale(product: ProductInPreSale): void
    handleChangeProductQntInPreSale(
        product: ProductInPreSale,
        qnt: number
    ): void
    handleChangeProductPriceInPreSale(
        product: ProductInPreSale,
        price: number
    ): void
    handleChangeProductDiscountInPreSale(
        product: ProductInPreSale,
        discount: number
    ): void
    handleChangeProductSelectedNamedPriceInPreSale(
        product: ProductInPreSale,
        selectedNamedPrice?: string
    ): void
    handleChangeClientInPreSale(client: ClientInfo | null): void

    validate(): boolean
    validatePreSale(): boolean
    handleCreateNewSale(
        data: HandleCreateNewSaleInterface
    ): Promise<HandleCreateNewSaleResponse>
    handleCreateNewPreSale(): Promise<void>
    handleChangeSelectedProducts(products: ProductInSale[]): void
    startNewPreSale(): void
    editSale(id: number): Promise<void>
    editPreSale(id: number): Promise<void>
}

const saleContext = createContext<SaleContextInterface>({
    saleInfo: saleContextDefault,
    setSaleInfo: () => {},
    preSaleInfo: preSaleContextDefault,
    setPreSaleInfo: () => {},
    handleChangeBill: () => {},
    handleAddNewProducts: () => {},
    handleLoadPreSale: () => {},
    handleRemoveProduct: () => {},
    handleChangeProductQnt: () => {},
    handleChangeProductPrice: () => {},
    handleChangeProductDiscount: () => {},
    handleChangeProductSelectedNamedPrice: () => {},
    handleChangeClient: () => {},
    handleChangePayment: () => {},
    handleChangeObservation: () => {},
    handleAddNewProductsInPreSale: () => {},
    handleRemoveProductInPreSale: () => {},
    handleChangeProductQntInPreSale: () => {},
    handleChangeProductPriceInPreSale: () => {},
    handleChangeProductDiscountInPreSale: () => {},
    handleChangeProductSelectedNamedPriceInPreSale: () => {},
    handleChangeClientInPreSale: () => {},
    validate: () => false,
    validatePreSale: () => false,
    handleCreateNewSale: async () => ({
        sale: null,
        pix: null,
        paraguayTransfer: null,
    }),
    handleCreateNewPreSale: () => Promise.resolve(),
    handleChangeSelectedProducts: () => {},
    startNewPreSale: () => {},
    editSale: () => Promise.resolve(),
    editPreSale: () => Promise.resolve(),
})

export function useSale() {
    return useContext(saleContext)
}

interface Props {
    children: ReactNode
}

export const SaleContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const password = usePassword()
    const navigate = useNavigate()
    const { mainCoin } = useCoin()
    const [saleInfo, setSaleInfo] = useState<SaleInfo>(saleContextDefault)
    const [preSaleInfo, setPreSaleInfo] = useState<PreSaleInfo>(
        preSaleContextDefault
    )
    const { discounts } = useDiscount()
    const { coins } = useCoin()
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    const getProductPrice = (
        product: ProductInfo,
        qnt: number,
        discount: number,
        client: ClientInfo | null,
        price?: number,
        selectedNamedPrice?: string
    ): { value: number; systemDiscount: DiscountInfo | null } => {
        if (typeof price === 'number') {
            return {
                systemDiscount: null,
                value: price,
            }
        }

        let currentPrice = product.salePrice

        const foundNamedPrice = product.namedPrice
            .filter(
                (price) =>
                    price.clients.find((c) => c.id === client?.id) ||
                    price.name === client?.prefferedPrice?.toString() ||
                    price.name === selectedNamedPrice
            )
            .sort((a, b) => a.price - b.price)

        if (foundNamedPrice.length) currentPrice = foundNamedPrice[0].price
        else {
            const foundQntPrices = product.qntPrice
                .filter((price) => qnt >= price.qnt)
                .sort((a, b) => b.qnt - a.qnt)
            if (foundQntPrices.length) currentPrice = foundQntPrices[0].price
        }

        let foundSystemDiscountProduct = 0
        let foundSystemDiscount: DiscountInfo | null = null

        discounts
            .filter((d) => d.isActive && d.activeDate)
            .sort((a, b) => Number(b.id) - Number(a.id))
            .forEach((discount) => {
                if (foundSystemDiscountProduct) return

                const filteredDiscountProducts = discount.discountProducts
                    .filter((dp) =>
                        dp.products.find((p) => p.id === product.id)
                    )
                    .sort((a, b) => b.discount - a.discount)

                if (filteredDiscountProducts.length) {
                    foundSystemDiscount = discount
                    foundSystemDiscountProduct =
                        filteredDiscountProducts[0].discount
                    return
                }
            })

        if (foundSystemDiscountProduct)
            currentPrice = currency(currentPrice).subtract(
                (currentPrice * foundSystemDiscountProduct) / 100
            ).value
        currentPrice = currency(currentPrice).subtract(
            (currentPrice * discount) / 100
        ).value

        return {
            value: currentPrice,
            systemDiscount: foundSystemDiscount,
        }
    }

    const getTotal = (products: ProductInPreSale[]): number => {
        const total = products
            .map((p) => p.subtotal)
            .reduce((total, current) => {
                return currency(total).add(current).value
            }, 0)
        return total
    }

    const getTotalIva = (products: ProductInPreSale[]): number => {
        return products.reduce((total, current) => {
            const iva =
                current.product.iva === IvaEnum.Exento
                    ? 0
                    : current.product.iva === IvaEnum.Cinco
                      ? 21
                      : 11
            if (!iva) return total
            return currency(total, { precision: 15 }).add(
                (current.price * current.qnt) / iva
            ).value
        }, 0)
    }

    const handleChangeBill = (bill: string) => {
        setSaleInfo((prev) => ({
            ...prev,
            bill: bill,
        }))
    }

    const handleAddNewProducts = (products: ProductInfo[]) => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch
        if (isMasterBranch && !saleInfo.branch) {
            snack.error('Seleccione una sucursal!')
            return
        }

        const newProducts = [...saleInfo.products]
        let count = saleInfo.productCount ?? 0

        products.forEach((product) => {
            const weight = product.weight
                ? product.weight
                : product.unitType === 'Un'
                  ? 1
                  : 0.01

            let stock = isMasterBranch
                ? product.branchStocks?.find(
                      (s) => s.branchId === saleInfo.branch?.id
                  )?.stock ?? 0
                : Number(product.stock)

            if (saleInfo.prevEditingProducts && saleInfo.isEditing) {
                const foundPrevProduct = saleInfo.prevEditingProducts.find(
                    (p) => p.product.id === product.id
                )

                if (foundPrevProduct) {
                    stock = currency(stock).add(foundPrevProduct.qnt).value
                }
            }

            const productInSale = saleInfo.products.find(
                (p) => p.product.id === product.id
            )
            if (productInSale && productInSale.product.autoSum) {
                handleChangeProductQnt(
                    productInSale,
                    currency(productInSale.qnt).add(weight).value
                )
                return
            }
            if (!product.isAtive) {
                snack.error('O produto está desativado!')
                return
            }
            if (!product.sellWithoutStock && stock < weight) {
                snack.error('O produto está sem estoque!')
                return
            }

            const { value, systemDiscount } = getProductPrice(
                product,
                weight,
                0,
                saleInfo.client,
                product.autoPrice ? product.salePrice : undefined
            )

            count += 1
            newProducts.push({
                product: product,
                price: value,
                qnt: weight,
                subtotal: value * weight,
                id: count,
                discount: 0,
                systemDiscount: systemDiscount,
            })
        })

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
            productCount: count,
        }))
    }

    console.log(getTotalIva(saleInfo.products))

    const handleLoadPreSale = (preSale: PreSaleInfo) => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch
        if (isMasterBranch && !saleInfo.branch) {
            snack.error('Selecione una sucursal!')
            return
        }
        if (isMasterBranch && saleInfo.branch?.id !== preSale.branch?.id) {
            snack.error('A pré-venda não pertence a filial!')
            return
        }

        const newProducts: ProductInSale[] = []
        let count = saleInfo.productCount ?? 0

        preSale.products.forEach((product) => {
            const stock = isMasterBranch
                ? product.product.branchStocks?.find(
                      (s) => s.branchId === saleInfo.branch?.id
                  )?.stock ?? 0
                : Number(product.product.stock)

            console.log(stock)

            if (!product.product.isAtive) {
                snack.error('O produto está desativado!')
                return
            }
            if (!product.product.sellWithoutStock && stock < product.qnt) {
                snack.error('O produto está sem estoque!')
                return
            }

            const { value, systemDiscount } = getProductPrice(
                product.product,
                product.qnt,
                product.discount,
                preSale.client,
                product.product.autoPrice
                    ? product.product.salePrice
                    : undefined,
                product.selectedNamedPrice
            )

            count += 1
            newProducts.push({
                product: product.product,
                price: value,
                qnt: product.qnt,
                subtotal: value * product.qnt,
                id: count,
                discount: product.discount,
                systemDiscount: systemDiscount,
                selectedNamedPrice: product.selectedNamedPrice,
            })
        })

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
            productCount: count,
            preSale: preSale,
            client: preSale.client,
        }))
    }

    const handleRemoveProduct = async (
        product: ProductInSale,
        pass?: string
    ) => {
        if (configs.useMasterPassword) {
            if (!pass) {
                password.show({
                    confirmPassword: false,
                    returnCb: (pass) => handleRemoveProduct(product, pass),
                })
                return
            } else {
                loading.show()
                try {
                    await validateMasterPassword(pass)
                    password.hide()
                    loading.hide()
                } catch (error) {
                    password.showError('Senha inválida!')
                    loading.hide()
                    return
                }
            }
        }

        const newProducts = [...saleInfo.products]
        const index = newProducts.findIndex((p) => p.id === product.id)
        if (index === -1) return

        newProducts.splice(index, 1)

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductQnt = (product: ProductInSale, qnt: number) => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch

        const canShowStock =
            accountInfo?.isMaster ||
            accountInfo?.rules.findIndex(
                (r) =>
                    r.group.toLowerCase() === 'produtos' &&
                    r.name.toLowerCase() === 'listar estoque'
            ) !== -1

        if (isMasterBranch && !saleInfo.branch) {
            snack.error('Selecione una sucursal!')
            return
        }

        let stock = isMasterBranch
            ? product.product.branchStocks?.find(
                  (s) => s.branchId === saleInfo.branch?.id
              )?.stock ?? 0
            : Number(product.product.stock)

        if (saleInfo.prevEditingProducts && saleInfo.isEditing) {
            const foundPrevProduct = saleInfo.prevEditingProducts.find(
                (p) => p.product.id === product.product.id
            )
            if (foundPrevProduct) {
                stock = currency(stock).add(foundPrevProduct.qnt).value
            }
        }

        if (!product.product.sellWithoutStock && stock < qnt) {
            if (canShowStock) snack.error('Estoque máximo do produto: ' + stock)
            else snack.error('O produto está sem estoque')
            return
        }

        const newProducts = [...saleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            qnt,
            newProduct.discount,
            saleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            newProduct.selectedNamedPrice
        )

        newProduct.qnt = qnt
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductPrice = (
        product: ProductInSale,
        price: number
    ) => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch
        if (isMasterBranch && !saleInfo.branch) {
            snack.error('Selecione una sucursal!')
            return
        }

        const newProducts = [...saleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            newProduct.discount,
            saleInfo.client,
            price,
            newProduct.selectedNamedPrice
        )

        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductDiscount = (
        product: ProductInSale,
        discount: number
    ) => {
        const newProducts = [...saleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            discount,
            saleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            newProduct.selectedNamedPrice
        )

        newProduct.discount = discount
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductSelectedNamedPrice = (
        product: ProductInSale,
        selectedNamedPrice: string
    ) => {
        const newProducts = [...saleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            newProduct.discount,
            saleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            selectedNamedPrice
        )

        newProduct.selectedNamedPrice = selectedNamedPrice
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeClient = (client: ClientInfo | null) => {
        if (client && !client.isActive) {
            snack.error('O cliente não está ativo!')
            return
        }

        const newProducts = [...saleInfo.products]

        for (const product of newProducts) {
            const { value } = getProductPrice(
                product.product,
                product.qnt,
                product.discount,
                client,
                product.product.autoPrice ? product.price : undefined,
                product.selectedNamedPrice
            )

            product.price = value
            product.subtotal = product.qnt * value
        }

        setSaleInfo((prev) => ({
            ...prev,
            client: client,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeObservation = (o: string) => {
        setSaleInfo((prev) => ({
            ...prev,
            observation: o,
        }))
    }

    const handleChangePayment = (
        paymentType: number,
        payment: PaymentInSale
    ) => {
        setSaleInfo((prev) => ({
            ...prev,
            paymentType: paymentType,
            payments: [payment],
        }))
    }

    const handleAddNewProductsInPreSale = (products: ProductInfo[]) => {
        const newProducts = [...preSaleInfo.products]
        let count = preSaleInfo.productCount ?? 0

        products.forEach((product) => {
            const weight = product.weight ?? 1

            const productInPreSale = preSaleInfo.products.find(
                (p) => p.product.id === product.id
            )
            if (productInPreSale && productInPreSale.product.autoSum) {
                handleChangeProductQntInPreSale(
                    productInPreSale,
                    currency(productInPreSale.qnt).add(weight).value
                )
                return
            }
            if (!product.isAtive) {
                snack.error('O produto está desativado!')
                return
            }

            const { value } = getProductPrice(
                product,
                weight,
                0,
                preSaleInfo.client,
                product.autoPrice ? product.salePrice : undefined
            )

            count += 1
            newProducts.push({
                product: product,
                qnt: weight,
                id: count,
                subtotal: value * weight,
                price: value,
                discount: 0,
            })
        })

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
            productCount: count,
        }))
    }

    const handleRemoveProductInPreSale = (product: ProductInPreSale) => {
        const newProducts = [...preSaleInfo.products]
        const index = newProducts.findIndex((p) => p.id === product.id)
        if (index === -1) return

        newProducts.splice(index, 1)

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductQntInPreSale = (
        product: ProductInPreSale,
        qnt: number
    ) => {
        const newProducts = [...preSaleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            qnt,
            newProduct.discount,
            preSaleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            newProduct.selectedNamedPrice
        )

        newProduct.qnt = qnt
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductPriceInPreSale = (
        product: ProductInPreSale,
        price: number
    ) => {
        const newProducts = [...preSaleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            newProduct.discount,
            preSaleInfo.client,
            price,
            newProduct.selectedNamedPrice
        )

        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductDiscountInPreSale = (
        product: ProductInPreSale,
        discount: number
    ) => {
        const newProducts = [...preSaleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            discount,
            preSaleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            newProduct.selectedNamedPrice
        )

        newProduct.discount = discount
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductSelectedNamedPriceInPreSale = (
        product: ProductInPreSale,
        selectedNamedPrice: string
    ) => {
        const newProducts = [...preSaleInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        const { value } = getProductPrice(
            product.product,
            newProduct.qnt,
            newProduct.discount,
            preSaleInfo.client,
            product.product.autoPrice ? newProduct.price : undefined,
            selectedNamedPrice
        )

        newProduct.selectedNamedPrice = selectedNamedPrice
        newProduct.price = value
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setPreSaleInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeClientInPreSale = (client: ClientInfo | null) => {
        if (client && !client.isActive) {
            snack.error('O cliente não está ativo!')
            return
        }

        const newProducts = [...preSaleInfo.products]

        for (const product of newProducts) {
            const { value } = getProductPrice(
                product.product,
                product.qnt,
                product.discount,
                client,
                product.product.autoPrice ? product.price : undefined,
                product.selectedNamedPrice
            )

            product.price = value
            product.subtotal = product.qnt * value
        }

        setPreSaleInfo((prev) => ({
            ...prev,
            client: client,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const validate = (): boolean => {
        if (!saleInfo.client) {
            snack.error('Selecione um cliente')
            return false
        }
        if (!saleInfo.products.length) {
            snack.error('Selecione um produto!')
            return false
        }
        for (const product of saleInfo.products) {
            if (!product.price) {
                snack.error(
                    `O producto ${product.product.name} está sin precio`
                )
                return false
            }
            if (!product.qnt) {
                snack.error(
                    `O producto ${product.product.name} está sin cantidad`
                )
                return false
            }
            if (
                product.product.autoPrice &&
                product.price < product.product.buyPrice
            ) {
                snack.error(
                    `O producto ${
                        product.product.name
                    } tiene un precio inferior al precio de compra (${mainCoin?.symbol} ${maskCurrency(
                        product.product.buyPrice,
                        mainCoin
                    )})`
                )
                return false
            }
        }
        if (saleInfo.products.filter((p) => !p.price || !p.qnt).length) {
            snack.error('A quantidade e o preço não podem ser 0!')
            return false
        }
        if (
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !saleInfo.branch
        ) {
            snack.error('Selecione una sucursal!')
            return false
        }
        return true
    }

    const validatePreSale = (): boolean => {
        /* if (!preSaleInfo.title) {
            snack.error('Selecione um título')
            return false
        } */
        if (!preSaleInfo.products.length) {
            snack.error('Selecione um produto!')
            return false
        }
        if (preSaleInfo.products.filter((p) => !p.qnt).length) {
            snack.error('A quantidade não pode ser 0!')
            return false
        }
        if (
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !preSaleInfo.branch
        ) {
            snack.error('Selecione una sucursal!')
            return false
        }
        return true
    }

    const handleCreateNewSale = async ({
        initialFees,
        parcels,
        payments,
        paymentType,
        useLegalNote,
        discount,
        changeCoin,
        employeeId,
        noteCoin,
        isPix,
        isParaguayTransfer,
    }: HandleCreateNewSaleInterface) => {
        let sale: SaleInfo | null = null
        let pix: Pix | null = null
        let paraguayTransfer: ParaguayTransfer | null = null
        loading.show()
        try {
            if (!saleInfo.isEditing) {
                const res = await createSale(
                    {
                        flag_forma_pagamento: paymentType ?? null,
                        id_cliente: saleInfo.client?.id
                            ? saleInfo.client.id
                            : undefined,
                        nota_entrega: saleInfo.observation
                            ? saleInfo.observation
                            : null,
                        sale_product: saleInfo.products.map((p) => ({
                            quantidade_produto: p.qnt,
                            id_produto: p.product.id ?? 0,
                            desconto: p.discount / 100,
                            nome_preco: p.selectedNamedPrice,
                            preco: p.product.autoPrice ? p.price : undefined,
                        })),
                        sale_payment: payments?.map((payment) => ({
                            id_metodo_pagamento:
                                payment.selectedPaymentMethod?.id ?? 0,
                            id_moeda: payment.selectedCoin?.id ?? 0,
                            valor_pagamento: payment.exchangeAmountPayed,
                            comprovante_imagem: payment.proofImage,
                            comprovante_texto: payment.proofText,
                            id_funcionario: null,
                        })),
                        desconto: discount ?? null,
                        id_moeda_troco: changeCoin?.id ?? null,
                        use_legal_note: useLegalNote,
                        id_moeda_fatura: noteCoin?.id,
                        id_filial_master: saleInfo.branch?.id,
                        id_pre_venda: saleInfo.preSale?.id,
                        juros_inicial:
                            paymentType === PaymentType.Parcel && initialFees
                                ? initialFees / 100
                                : undefined,
                        parcelas:
                            paymentType === PaymentType.Parcel && parcels
                                ? parcels
                                : undefined,
                        id_funcionario: saleInfo.employee?.id,
                        porcentagem_guia:
                            typeof saleInfo.guidePercentage === 'number'
                                ? saleInfo.guidePercentage
                                : undefined,
                        id_funcionario_pagamento: employeeId,
                        isPix: isPix,
                        isParaguayTransfer: isParaguayTransfer,
                    },
                    coins
                )
                sale = res.sale
                pix = res.pix
                paraguayTransfer = res.paraguayTransfer
            } else {
                await updateSale({
                    id_venda: saleInfo.id as number,
                    sale_product: saleInfo.products.map((p) => ({
                        quantidade_produto: p.qnt,
                        id_produto: p.product.id ?? 0,
                        desconto: p.discount / 100,
                        nome_preco: p.selectedNamedPrice,
                        preco: p.product.autoPrice ? p.price : undefined,
                    })),
                    nota_entrega: saleInfo.observation
                        ? saleInfo.observation
                        : null,
                })
            }

            snack.success(
                `Venda ${
                    !saleInfo.isEditing ? 'criada' : 'atualizada'
                } com sucesso!`,
                2000
            )
            /* navigate('/system/sale') */
            setSaleInfo({ ...saleContextDefault })
        } catch (error) {
            if (error instanceof CashierClosedSaleError)
                snack.error('O caixa está fechado!')
            else snack.connectionFail(error)
        }
        loading.hide()

        return {
            sale,
            pix,
            paraguayTransfer,
        }
    }

    const handleCreateNewPreSale = async () => {
        loading.show()
        try {
            const body: CreatePreSaleInterface = {
                titulo: preSaleInfo.title,
                produtos: preSaleInfo.products.map((p) => ({
                    id_produto: p.product.id ?? 0,
                    quantidade_produto: p.qnt,
                    preco_selecionado: p.selectedNamedPrice,
                    desconto: p.discount / 100,
                    preco: p.product.autoPrice ? p.price : undefined,
                })),
                flag_ativo: 1,
                anotacao: preSaleInfo.annotation,
                id_cliente: preSaleInfo.client?.id
                    ? preSaleInfo.client.id
                    : null,
                id_filial_master: preSaleInfo.branch?.id,
            }
            if (!preSaleInfo.isEditing) await createPreSale(body)
            else
                await updatePreSale({
                    ...body,
                    id_pre_venda: preSaleInfo.id as number,
                })
            snack.success(
                `Pré-venda ${
                    !preSaleInfo.isEditing ? 'criada' : 'atualizada'
                } com sucesso!`,
                2000
            )
            navigate('/system/sale/budget')
            setPreSaleInfo({ ...preSaleContextDefault })
        } catch (error) {
            if (error instanceof CashierClosedSaleError)
                snack.error('O caixa está fechado!')
            else snack.connectionFail(error)
        }
        loading.hide()
    }

    const handleChangeSelectedProducts = (products: ProductInSale[]) => {
        setSaleInfo((prev) => ({
            ...prev,
            selectedProducts: products,
        }))
    }

    const startNewPreSale = () => {
        setPreSaleInfo({ ...preSaleContextDefault })
    }

    const editSale = async (id: number) => {
        try {
            const sale = await getSaleById(id, coins)

            sale.products.forEach((p, i) => {
                p.discount *= 100
                const { value } = getProductPrice(
                    p.product,
                    p.qnt,
                    p.discount,
                    sale.client,
                    p.product.autoPrice ? p.price : undefined,
                    p.selectedNamedPrice
                )

                p.price = value
                p.subtotal = value * p.qnt
                p.id = i + 1
            })

            setSaleInfo(() => ({
                ...sale,
                total: getTotal(sale.products),
                totalIva: getTotalIva(sale.products),
                isEditing: true,
                id,
                prevEditingProducts: JSON.parse(JSON.stringify(sale.products)),
                productCount: sale.products.length,
            }))
        } catch (error) {
            console.log(error)
            snack.error('Venda não encontrada')
            throw new Error('Sale not found')
        }
    }

    const editPreSale = async (id: number) => {
        try {
            const preSale = await getPreSaleById(id)

            preSale.products.forEach((p, i) => {
                const { value } = getProductPrice(
                    p.product,
                    p.qnt,
                    p.discount,
                    preSale.client,
                    p.product.autoPrice ? p.price : undefined,
                    p.selectedNamedPrice
                )

                p.price = value
                p.subtotal = value * p.qnt
                p.id = i + 1
            })

            setPreSaleInfo(() => ({
                ...preSale,
                total: getTotal(preSale.products),
                totalIva: getTotalIva(preSale.products),
                isEditing: true,
                productCount: preSale.products.length,
            }))
        } catch (error) {
            console.log(error)
            snack.error('Orçamento não encontrado')
            throw new Error('Budget not found')
        }
    }

    return (
        <saleContext.Provider
            value={{
                saleInfo,
                setSaleInfo,
                preSaleInfo,
                setPreSaleInfo,
                validatePreSale,
                handleCreateNewPreSale,
                handleChangeBill,
                handleAddNewProducts,
                handleLoadPreSale,
                handleRemoveProduct,
                handleChangeProductQnt,
                handleChangeProductPrice,
                handleChangeProductDiscount,
                handleChangeProductSelectedNamedPrice,
                handleChangeClient,
                handleChangePayment,
                handleAddNewProductsInPreSale,
                handleRemoveProductInPreSale,
                handleChangeProductQntInPreSale,
                handleChangeProductPriceInPreSale,
                handleChangeProductDiscountInPreSale,
                handleChangeProductSelectedNamedPriceInPreSale,
                handleChangeClientInPreSale,
                validate,
                handleCreateNewSale,
                handleChangeObservation,
                handleChangeSelectedProducts,
                startNewPreSale,
                editSale,
                editPreSale,
            }}
        >
            {children}
        </saleContext.Provider>
    )
}
