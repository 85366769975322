import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import {
    CashierAlreadyInUseError,
    InvalidCashierError,
    LoginCashier,
    createCashier,
    getCashierById,
    listCashiers,
    updateCashier,
} from '../services/cashier'
import { useAccount } from './account_context'
import { CoinInfo } from './coin_context'
import { PaymentMethodInfo } from './payment_method_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { BranchInfo } from './branch_context'
import GenericModal from '../components/GenericModal'
import { StringField } from '../components/StringField'

export interface CashierInfo {
    id: number
    name: string
    hash: string
    isActive: boolean
    branch: BranchInfo | null
}

export interface CraeteCashierInfo extends Omit<CashierInfo, 'id'> {
    id?: number
    isEditing: boolean
}

export interface CashierError {
    name: string
    hash: string
    branch: string
}

export enum CashierHistoricStatus {
    Open = 0,
    Closed = 1,
}

export interface CashierValue {
    id?: number
    coin?: CoinInfo
    type?: number
    value: number
}

export enum CashierValueEnum {
    Initial = 0,
    Final = 1,
    In = 2,
    Out = 3,
}

export const CashierValueLabel: Record<number, string> = {
    0: 'Dinheiro inicial',
    1: 'Dinheiro final',
    2: 'Entrada',
    3: 'Saída',
}

export interface CashierHistoric {
    id: number
    cashierId: number
    status: number
    openingDate: string
    openingHour: string
    closingDate: string
    closingHour: string
    isSummerTime: boolean
    closingIsSummerTime: boolean
    cashier: CashierInfo
    values: CashierValue[]
}

export interface GeneralCashierExpectedAmountInfo {
    id: number
    coin?: CoinInfo
    paymentMethod?: PaymentMethodInfo
    initialValue: number
    amountIn: number
    amountOut: number
    expectedAmount: number
}

export interface DetailsCashierExpectedAmountInfo {
    type:
        | 'initialCash'
        | 'paymentSale'
        | 'paymentSaleChange'
        | 'paymentPurchase'
        | 'paymentPurchaseChange'
        | 'spent'
        | 'employee'
        | 'cashierIn'
        | 'cashierOut'
    amount: number
    date: string
    time: string
    isSummerTime: boolean
    referencedId: number
    coinId: number
    paymentMethod?: PaymentMethodInfo
    referencedPerson?: string
    referenceParentId?: number
    coin?: CoinInfo
    user?: string
}

export interface CashierExpectedAmountInfo {
    general: GeneralCashierExpectedAmountInfo[]
    datails: DetailsCashierExpectedAmountInfo[]
}

const cashierInfoDefault: CraeteCashierInfo = {
    name: '',
    hash: '',
    isActive: true,
    isEditing: false,
    branch: null,
}

const cashierErrorDefault: CashierError = {
    name: '',
    hash: '',
    branch: '',
}

export interface CashierContextInterface {
    loggedCashier: CashierInfo | null
    createCashierInfo: CraeteCashierInfo
    setCreateCashierInfo: Dispatch<SetStateAction<CraeteCashierInfo>>
    cashierError: CashierError
    setCashierError: Dispatch<SetStateAction<CashierError>>
    validate(): boolean
    handleCreateNewCashier(): Promise<void>
    startNewCashier(): void
    editCashier(id: number): Promise<void>
}

const cashierContextDefault: CashierContextInterface = {
    loggedCashier: null,
    createCashierInfo: cashierInfoDefault,
    setCreateCashierInfo: () => {},
    cashierError: cashierErrorDefault,
    setCashierError: () => {},
    validate: () => false,
    handleCreateNewCashier: () => Promise.resolve(),
    startNewCashier: () => {},
    editCashier: () => Promise.resolve(),
}

const CashierContext = createContext<CashierContextInterface>(
    cashierContextDefault
)

export function useCashier() {
    return useContext(CashierContext)
}

interface Props {
    children: ReactNode
}

export function CashierContextProvider({ children }: Props) {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [createCashierInfo, setCreateCashierInfo] =
        useState<CraeteCashierInfo>(cashierInfoDefault)
    const [cashierError, setCashierError] =
        useState<CashierError>(cashierErrorDefault)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()
    const [loggedCashier, setLoggedCashier] = useState<CashierInfo | null>(null)
    const [newCashierHash, setNewCashierHash] = useState('')
    const [openCashierHashModal, setOpenCashierHashModal] = useState(false)
    const newCashierRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (!openCashierHashModal) return

        setTimeout(() => {
            newCashierRef.current?.focus()
        }, 100)
    }, [openCashierHashModal])

    useEffect(() => {
        if (!accountInfo || !configs.id || loggedCashier) return
        manageHash()
    }, [accountInfo, configs])

    const manageHash = async () => {
        const localStorageHash = localStorage.getItem('cashierHash')

        const cashierHash = localStorageHash ?? newCashierHash

        try {
            const res = await LoginCashier({ hash_caixa: cashierHash })
            setLoggedCashier(res)
            localStorage.setItem('cashierHash', cashierHash)
            if (!localStorageHash) window.location.reload()
        } catch (error) {
            if (error instanceof InvalidCashierError) {
                localStorage.removeItem('cashierHash')
                setOpenCashierHashModal(true)
                setNewCashierHash('')
            }
        }
    }

    const validate = (): boolean => {
        let isOk = true
        const newCashierError = { ...cashierErrorDefault }

        if (!createCashierInfo.name) {
            newCashierError.name = 'Campo obrigatório'
            isOk = false
        }
        if (!createCashierInfo.hash) {
            newCashierError.hash = 'Campo obrigatório'
            isOk = false
        }
        if (
            accountInfo?.isMaster &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !createCashierInfo.branch
        ) {
            isOk = false
            newCashierError.branch = 'Campo obrigatório'
        }

        setCashierError({ ...newCashierError })
        return isOk
    }

    const handleCreateNewCashier = async () => {
        loading.show()
        try {
            if (!createCashierInfo.isEditing)
                await createCashier({
                    nome: createCashierInfo.name,
                    hash: createCashierInfo.hash,
                    flag_ativo: Number(createCashierInfo.isActive),
                    id_filial_master: createCashierInfo.branch?.id,
                })
            else
                await updateCashier({
                    id_caixa: createCashierInfo.id ?? 0,
                    nome: createCashierInfo.name,
                    hash: createCashierInfo.hash,
                    flag_ativo: Number(createCashierInfo.isActive),
                    id_filial_master: createCashierInfo.branch?.id,
                })
            snack.success(
                `Caixa ${
                    !createCashierInfo.isEditing ? 'criado' : 'atualizado'
                } com sucesso!`
            )
            navigate('/system/cashier')
            setCreateCashierInfo({ ...cashierInfoDefault })
            setCashierError({ ...cashierErrorDefault })
        } catch (error) {
            if (error instanceof CashierAlreadyInUseError)
                setCashierError((prev) => ({
                    ...prev,
                    hash: 'Código já está em uso',
                }))
            else snack.connectionFail(error)
        }
        loading.hide()
    }

    const startNewCashier = () => {
        setCreateCashierInfo({ ...cashierInfoDefault })
        setCashierError({ ...cashierErrorDefault })
    }

    const editCashier = async (id: number) => {
        try {
            const cashier = await getCashierById(id)

            setCreateCashierInfo((prev) => ({
                ...cashier,
                isEditing: true,
            }))
            setCashierError({ ...cashierErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Caixa não encontrado')
            throw new Error('Cashier not found')
        }
    }

    return (
        <>
            <GenericModal
                open={openCashierHashModal}
                handleClose={() => {}}
                textButton="Confirmar"
                title="Seleccione el caja"
                handleSubmit={() => {
                    setOpenCashierHashModal(false)
                    manageHash()
                }}
            >
                <StringField
                    label="Caja"
                    value={newCashierHash}
                    setValue={setNewCashierHash}
                    sx={{ width: '100%', mt: '5px' }}
                    ref={newCashierRef}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setOpenCashierHashModal(false)
                            manageHash()
                        }
                    }}
                />
            </GenericModal>
            <CashierContext.Provider
                value={{
                    loggedCashier,
                    createCashierInfo,
                    setCreateCashierInfo,
                    cashierError,
                    setCashierError,
                    validate,
                    handleCreateNewCashier,
                    startNewCashier,
                    editCashier,
                }}
            >
                {children}
            </CashierContext.Provider>
        </>
    )
}
