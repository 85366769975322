import { CircularProgress, createTheme, ThemeProvider } from '@mui/material'
import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom'
import Layout from './components/Layout'
import { AccountContextProvider } from './context/account_context'
import { BranchContextProvider } from './context/branch_context'
import { BuyContextProvider } from './context/buy_context'
import { CashierContextProvider } from './context/cashier_context'
import { ClientProvider } from './context/client_context'
import { CoinContextProvider } from './context/coin_context'
import { ConfigsContextProvider } from './context/configs_context'
import { DiscountContextProvider } from './context/discount_context'
import { LoadingProvider } from './context/loading_context'
import { NoteContextProvider } from './context/notes_context'
import { PasswordContextProvider } from './context/password_context'
import { PaymentMethodContextProvider } from './context/payment_method_context'
import { ProductProvider } from './context/product_context'
import { SaleContextProvider } from './context/sale_context'
import { ServiceContextProvider } from './context/service_context'
import { SnackProvider } from './context/snack_context'
import { SpentContextProvider } from './context/spent_context'
import { SupplierProvider } from './context/supplier_context'

import jwtDecode from 'jwt-decode'
import { RenderNote } from './pages/RenderNote'
import { doLogout, doRefreshToken, isLogged } from './services/auth'
import { getLastFrontEndVersion } from './services/utils'

const Accounts = lazy(() => import('./pages/Accounts'))
const Branch = lazy(() => import('./pages/Branch'))
const Buys = lazy(() => import('./pages/Buy'))
const Cashier = lazy(() => import('./pages/Cashier'))
const Clients = lazy(() => import('./pages/Clients'))
const Coins = lazy(() => import('./pages/Coin'))
const Configs = lazy(() => import('./pages/Configs'))
const Login = lazy(() => import('./pages/Login'))
const Notes = lazy(() => import('./pages/Note'))
const PaymentMethod = lazy(() => import('./pages/PaymentMethod'))
const Products = lazy(() => import('./pages/Products'))
const Sales = lazy(() => import('./pages/Sale'))
const Spents = lazy(() => import('./pages/Spent'))
const Supplier = lazy(() => import('./pages/Supplier'))
const Documents = lazy(() => import('./pages/Document'))
const Services = lazy(() => import('./pages/Service'))
const Employee = lazy(() => import('./pages/Employee'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Vehicle = lazy(() => import('./pages/Vehicle'))

function System() {
    return (
        <ConfigsContextProvider>
            <AccountContextProvider>
                <BranchContextProvider>
                    <PaymentMethodContextProvider>
                        <CashierContextProvider>
                            <PasswordContextProvider>
                                <CoinContextProvider>
                                    <ClientProvider>
                                        <SupplierProvider>
                                            <ProductProvider>
                                                <NoteContextProvider>
                                                    <DiscountContextProvider>
                                                        <BuyContextProvider>
                                                            <SaleContextProvider>
                                                                <SpentContextProvider>
                                                                    <ServiceContextProvider>
                                                                        <Layout>
                                                                            <Routes>
                                                                                <Route
                                                                                    path="/product/*"
                                                                                    element={
                                                                                        <Products />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/client/*"
                                                                                    element={
                                                                                        <Clients />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/supplier/*"
                                                                                    element={
                                                                                        <Supplier />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/buy/*"
                                                                                    element={
                                                                                        <Buys />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/sale/*"
                                                                                    element={
                                                                                        <Sales />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/payment-method/*"
                                                                                    element={
                                                                                        <PaymentMethod />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/note/*"
                                                                                    element={
                                                                                        <Notes />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/spent/*"
                                                                                    element={
                                                                                        <Spents />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/service/*"
                                                                                    element={
                                                                                        <Services />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/coin/*"
                                                                                    element={
                                                                                        <Coins />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/employee/*"
                                                                                    element={
                                                                                        <Employee />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/document/*"
                                                                                    element={
                                                                                        <Documents />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/account/*"
                                                                                    element={
                                                                                        <Accounts />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/cashier/*"
                                                                                    element={
                                                                                        <Cashier />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/vehicle/*"
                                                                                    element={
                                                                                        <Vehicle />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/dashboard"
                                                                                    element={
                                                                                        <Dashboard />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/branch/*"
                                                                                    element={
                                                                                        <Branch />
                                                                                    }
                                                                                />

                                                                                <Route
                                                                                    path="/configs"
                                                                                    element={
                                                                                        <Configs />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="/render-note"
                                                                                    element={
                                                                                        <RenderNote />
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path="*"
                                                                                    element={
                                                                                        <Navigate to="product" />
                                                                                    }
                                                                                />
                                                                            </Routes>
                                                                        </Layout>
                                                                    </ServiceContextProvider>
                                                                </SpentContextProvider>
                                                            </SaleContextProvider>
                                                        </BuyContextProvider>
                                                    </DiscountContextProvider>
                                                </NoteContextProvider>
                                            </ProductProvider>
                                        </SupplierProvider>
                                    </ClientProvider>
                                </CoinContextProvider>
                            </PasswordContextProvider>
                        </CashierContextProvider>
                    </PaymentMethodContextProvider>
                </BranchContextProvider>
            </AccountContextProvider>
        </ConfigsContextProvider>
    )
}

function App() {
    const location = useLocation()
    const navigate = useNavigate()
    const refreshTokenTimeout = useRef<NodeJS.Timeout>()
    const versionVerificationTimeout = useRef<NodeJS.Timeout>()
    const windowRefreshTimeout = useRef<NodeJS.Timeout>()
    const lastVersion = useRef<Date>()
    const needToRefresh = useRef<boolean>(false)
    const isInactive = useRef<boolean>(false)

    useEffect(() => {
        if (process.env.REACT_APP_MODE !== 'cloud') return

        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        document.body.appendChild(script)

        const events = ['mousemove', 'keydown', 'scroll', 'click']

        events.forEach((event) => {
            document.addEventListener(event, () => {
                isInactive.current = false
                clearTimeout(windowRefreshTimeout.current)
                windowRefreshTimeout.current = setTimeout(
                    () => {
                        isInactive.current = true
                    },
                    5 * 60 * 1000
                )
            })
        })

        versionVerificationTimeout.current = setInterval(async () => {
            const versionResponse = await getLastFrontEndVersion()
            if (
                lastVersion.current &&
                versionResponse.valueOf() > lastVersion.current.valueOf()
            )
                needToRefresh.current = true
            lastVersion.current = versionResponse
            if(isInactive.current && needToRefresh.current) window.location.reload()
        }, 1000 * 60)

        return () => {
            clearInterval(versionVerificationTimeout.current)
            clearTimeout(windowRefreshTimeout.current)
        }
    }, [])

    useEffect(() => {
        const logged = isLogged()

        if (!logged) {
            doLogout()
            if (location.pathname.includes('/system')) navigate('/login')
        } else {
            handleRefreshToken()
            if (location.pathname.includes('/login')) navigate('/system')
        }

        return () => {
            clearTimeout(refreshTokenTimeout.current)
        }
    }, [location.pathname])

    const handleRefreshToken = () => {
        const token = localStorage.getItem('token')
        const timeDiff = localStorage.getItem('timeDiff')

        if (!token || !timeDiff) return

        const { exp } = jwtDecode<any>(token)
        let timeout =
            Number(exp) -
            (Math.floor(new Date().valueOf() / 1000) - Number(timeDiff))

        timeout -= 60 * 60

        clearTimeout(refreshTokenTimeout.current)
        refreshTokenTimeout.current = setTimeout(
            async () => {
                try {
                    await doRefreshToken()
                    handleRefreshToken()
                } catch (error) {
                    doLogout()
                    navigate('/login')
                }
            },
            timeout < 0 ? 0 : timeout * 1000
        )
    }

    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins',
        },
        palette: {
            primary: {
                main: '#3a49e7',
                dark: '#4D3FB4',
            },
            secondary: {
                main: '#FFFFFF',
            },
            text: {
                primary: '#333333',
            },
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': {
                            color: '#333',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#333',
                            },
                            '& fieldset': {
                                borderColor: '#AAA',
                            },
                            '&:hover fieldset': {
                                borderColor: '#333',
                            },
                        },
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        '& label.Mui-focused': {
                            color: '#333',
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#333',
                            },
                            '& fieldset': {
                                borderColor: '#AAA',
                            },
                            '&:hover fieldset': {
                                borderColor: '#333',
                            },
                        },
                    },
                },
            },
            /* MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: '#2e7d32',
                        },
                    },
                },
            }, */
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: '#1976d2',
                        },
                    },
                },
            },
        },
    })

    return (
        <Suspense
            fallback={
                <CircularProgress
                    sx={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                    }}
                />
            }
        >
            <ThemeProvider theme={theme}>
                <LoadingProvider>
                    <SnackProvider>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/system/*" element={<System />} />
                            <Route
                                path="*"
                                element={<Navigate to="/login" />}
                            />
                        </Routes>
                    </SnackProvider>
                </LoadingProvider>
            </ThemeProvider>
        </Suspense>
    )
}

export default App
