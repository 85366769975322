import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
    ReactNode,
} from 'react'
import { NationalityEnum } from './supplier_context'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import {
    ClientDocumentAlreadyInUseError,
    ClientEmailAlreadyInUseError,
    CreateClientInterface,
    createClient,
    getClientById,
    updateClient,
} from '../services/client'
import { useNavigate } from 'react-router-dom'
import { validateEmail, validateRuc } from '../utils/maks'

export enum ClientDocumentType {
    RUC = 0,
    CedulaParaguaia = 1,
    Passaporte = 2,
    CedulaEstrangeira = 3,
    ComprovanteDeResidencia = 4,
}

export const ClientDocumentTypeLabel: Record<number, string> = {
    0: 'RUC',
    1: 'Cédula paraguaya',
    2: 'Pasaporte',
    3: 'Cédula extranjera',
    4: 'Carnet de residencia',
}

export interface ClientInfo {
    id?: number
    name: string
    document: string
    email: string
    address: string
    nationality: NationalityEnum
    phoneNumbers: string[]
    allowCredit: boolean
    creditDays: string
    creditValue: number
    creditUsed: number
    isActive: boolean
    fees: number
    chargeFees: boolean
    prefferedPrice: number | null
    expirationType: number
    documentType: number
    isPublicOrgan: boolean
    clientName?: string
}

export enum ExpirationType {
    DaysAfter = 0,
    FixedDay = 1,
}

export const anonymousClient: ClientInfo = {
    id: 0,
    name: 'Cliente anônimo',
    document: '',
    email: '',
    address: '',
    nationality: 1,
    phoneNumbers: [],
    allowCredit: false,
    creditDays: '',
    creditValue: 0,
    creditUsed: 0,
    isActive: true,
    fees: 0,
    chargeFees: false,
    prefferedPrice: null,
    expirationType: ExpirationType.DaysAfter,
    documentType: ClientDocumentType.RUC,
    isPublicOrgan: false,
}

export interface CreateClientInfo extends ClientInfo {
    step: number
    isEditing: boolean
}

export interface ClientErrorInfo {
    name: string
    document: string
    email: string
    address: string
    phoneNumbers: string[]
    creditDays: string
    creditValue: string
    fees: string
    prefferedPrice: string
}

const createClientInfoDefault: CreateClientInfo = {
    name: '',
    document: '',
    email: '',
    address: '',
    nationality: NationalityEnum.Native,
    phoneNumbers: [''],
    allowCredit: false,
    creditDays: '30',
    creditValue: 0,
    step: 1,
    isEditing: false,
    creditUsed: 0,
    isActive: true,
    fees: 0,
    chargeFees: false,
    prefferedPrice: null,
    expirationType: ExpirationType.DaysAfter,
    documentType: ClientDocumentType.RUC,
    isPublicOrgan: false,
}

const clientErrorDefault: ClientErrorInfo = {
    name: '',
    document: '',
    email: '',
    address: '',
    phoneNumbers: [''],
    creditDays: '',
    creditValue: '',
    fees: '',
    prefferedPrice: '',
}

export interface ClientContextInterface {
    clientInfo: CreateClientInfo
    setClientInfo: Dispatch<SetStateAction<CreateClientInfo>>
    clientError: ClientErrorInfo
    setClientError: Dispatch<SetStateAction<ClientErrorInfo>>
    validate(validateAll?: boolean): boolean
    handleCreateNewClient(): Promise<ClientInfo | null>
    startNewClient(): void
    editClient(clientId: number): Promise<void>
}

const clientContext = createContext<ClientContextInterface>({
    clientInfo: createClientInfoDefault,
    setClientInfo: () => {},
    clientError: clientErrorDefault,
    setClientError: () => {},
    validate: () => {
        return false
    },
    handleCreateNewClient: async () => {
        return null
    },
    startNewClient: () => {},
    editClient: async () => {},
})

export function useClient() {
    return useContext(clientContext)
}

interface Props {
    children: ReactNode
}

export const ClientProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [clientInfo, setClientInfo] = useState<CreateClientInfo>(
        createClientInfoDefault
    )
    const [clientError, setClientError] =
        useState<ClientErrorInfo>(clientErrorDefault)

    const startNewClient = () => {
        setClientInfo({ ...createClientInfoDefault })
        setClientError({ ...clientErrorDefault })
    }

    const editClient = async (id: number) => {
        try {
            const client = await getClientById(id)

            setClientInfo(() => ({
                ...client,
                step: 1,
                isEditing: true,
            }))
            setClientError({ ...clientErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Cliente não encontrado')
            throw new Error('Client not found')
        }
    }

    const validate = (): boolean => {
        let isOk = true
        const newClientErrorInfo = { ...clientErrorDefault }

        if (!clientInfo.name) {
            isOk = false
            newClientErrorInfo.name = 'Campo obrigatório'
        }
        if (!clientInfo.document) {
            isOk = false
            newClientErrorInfo.document = 'Campo obrigatório'
        }
        if (clientInfo.document && !validateRuc(clientInfo.document)) {
            isOk = false
            newClientErrorInfo.document = 'Documento inválido'
        }
        if (!clientInfo.email) {
            isOk = false
            newClientErrorInfo.email = 'Campo obrigatório'
        }
        if (clientInfo.email && !validateEmail(clientInfo.email)) {
            isOk = false
            newClientErrorInfo.email = 'Email inválido'
        }
        /* if (!clientInfo.address) {
            isOk = false
            newClientErrorInfo.address = 'Campo obrigatório'
        } */
        if (!clientInfo.phoneNumbers[0]) {
            isOk = false
            newClientErrorInfo.phoneNumbers[0] = 'Campo obrigatório'
        }
        if (clientInfo.allowCredit && !clientInfo.creditValue) {
            isOk = false
            newClientErrorInfo.creditValue = 'Campo obrigatório'
        }
        if (clientInfo.allowCredit && !parseInt(clientInfo.creditDays)) {
            isOk = false
            newClientErrorInfo.creditDays = 'Campo obrigatório'
        }

        for (let i = 0; i < clientInfo.phoneNumbers.length; i++) {
            if (clientInfo.phoneNumbers[i].length > 15) {
                isOk = false
                newClientErrorInfo.phoneNumbers[i] =
                    'Deve conter menos de 15 caracteres'
            }
        }

        setClientError({ ...newClientErrorInfo })
        return isOk
    }

    const handleCreateNewClient = async (): Promise<ClientInfo | null> => {
        let client: ClientInfo | null = null
        loading.show()
        try {
            const body: CreateClientInterface = {
                nome: clientInfo.name,
                numero_documento: clientInfo.document,
                email: clientInfo.email || null,
                endereco: {
                    nome: clientInfo.address,
                    flag_nacionalidade: clientInfo.nationality,
                },
                flag_ativo: clientInfo.isActive ? 1 : 0,
                flag_fiado: clientInfo.allowCredit ? 1 : 0,
                juros_credito_dia: parseInt(clientInfo.creditDays)
                    ? parseInt(clientInfo.creditDays)
                    : 0,
                phones: clientInfo.phoneNumbers
                    .filter((p) => p)
                    .map((phoneNumber) => {
                        return { telefone: phoneNumber }
                    }),
                valor_credito: clientInfo.creditValue,
                taxa_juros: clientInfo.fees / 100,
                flag_cobrar_juros: clientInfo.chargeFees ? 1 : 0,
                preco_preferivel: clientInfo.prefferedPrice,
                flag_tipo_vencimento: clientInfo.expirationType,
                flag_tipo_documento: clientInfo.documentType,
                flag_orgao_publico: Number(clientInfo.isPublicOrgan),
            }

            if (!clientInfo.isEditing) {
                const id = await createClient(body)
                snack.success('Cliente registrado com sucesso!')
                client = {
                    ...clientInfo,
                    id: id,
                }
            } else {
                await updateClient({ ...body, id_cliente: clientInfo.id ?? 0 })
                snack.success('Cliente editado com sucesso!')
                client = {
                    ...clientInfo,
                }
            }
        } catch (error) {
            const newClientError = { ...clientErrorDefault }
            if (error instanceof ClientEmailAlreadyInUseError) {
                newClientError.email = 'Este email já está em uso'
                setClientError(newClientError)
            } else if (error instanceof ClientDocumentAlreadyInUseError) {
                newClientError.document = 'Este documento já está em uso'
                setClientError(newClientError)
            } else snack.error('Erro ao criar cliente')
        }
        loading.hide()
        return client
    }

    return (
        <clientContext.Provider
            value={{
                clientInfo,
                setClientInfo,
                clientError,
                setClientError,
                validate,
                handleCreateNewClient,
                startNewClient,
                editClient,
            }}
        >
            {children}
        </clientContext.Provider>
    )
}
