import {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react'
import { useSnack } from './snack_context'
import { useLoading } from './loading_context'
import { createGroup, getAllGroups } from '../services/group'
import { createSubGroup, getSubGroupsByGroupId } from '../services/subgroup'
import { createMark, getAllMarks } from '../services/mark'
import { createModel, getModelsByMarkId } from '../services/model'
import {
    BarcodeAlreadyInUseError,
    CreateProductInterface,
    createProduct,
    getProductById,
    updateProduct,
} from '../services/product'
import { useNavigate } from 'react-router-dom'
import { AccountInfo, useAccount } from './account_context'
import { ImageInterface } from '../pages/Accounts/Register'
import { ClientInfo } from './client_context'
import { CoinInfo } from './coin_context'
import { BranchInfo } from './branch_context'
import { CashierInfo } from './cashier_context'
import { ExchangeInfo } from './buy_context'
import { SupplierInfo } from './supplier_context'

export enum StockLevelEnum {
    LOW = 0,
    MEDIUM = 1,
    HIGH = 2,
    NEGATIVE = 3,
    POSITIVE = 4,
}

export const StockLevelLabel: Record<number, string> = {
    0: 'Stock baixo',
    1: 'Stock médio',
    2: 'Stock alto',
    3: 'Stock negativo',
    4: 'Stock positivo',
}

export enum ProductOrderEnum {
    Name = 'name',
    Localization = 'localization',
    Price = 'price',
}

export const ProductOrderLabel: Record<string, string> = {
    name: 'Nome',
    localization: 'Localização',
    price: 'Preço',
}

export interface Group {
    id: number
    name: string
}

export interface SubGroup {
    id: number
    name: string
    groupId: number
}

export interface Mark {
    id: number
    name: string
}

export interface Model {
    id: number
    name: string
    markId: number
}

export enum IvaEnum {
    Exento = 0,
    Cinco = 1,
    Dez = 2,
}

export enum UnitType {
    Unit = 'Un',
    Kg = 'Kg',
}

export interface BranchStock {
    id: number
    branchId: number
    stock: number
    unitType: string
    minStock: number | null
    idealStock: number | null
}

export interface NamedPrice {
    name: string
    price: number
    porcentGain: number
    clients: ClientInfo[]
}

export enum BarcodeTypeEnum {
    Barcode = 0,
    Internal = 1,
}

export interface ProductInfo {
    id?: number
    name: string
    aplication: string | null
    selectedGroup: Group | null
    selectedSubGroup: SubGroup | null
    selectedMark: Mark | null
    selectedModel: Model | null
    minStock: number | null
    idealStock: number | null
    stock: number | null
    observation: string | null
    barcodes: string[]
    internalCodes: string[]
    unitType: string
    buyPrice: number
    salePrice: number
    porcentGain: number
    qntPrice: {
        price: number
        qnt: number
    }[]
    namedPrice: NamedPrice[]
    image: ImageInterface | null
    iva: number
    showIva: boolean
    sellWithoutStock: boolean
    isAtive: boolean
    autoSum: boolean
    autoPrice: boolean
    weight?: number
    branchStocks?: BranchStock[]
    supplier: SupplierInfo | null
    localizations: {
        value: string
        branchId?: number
    }[]
    productsStockAfterOperation: {
        product: ProductInfo | null
        qnt: number
    }[]
}

export interface ProductHistoricInfo {
    id: number
    operationId: number
    date: string
    hour: string
    value: number
    qnt: number
    type:
        | 'entrada'
        | 'saida'
        | 'carga_estoque'
        | 'transferencia_estoque'
        | 'nota_credito'
        | 'carga_estoque_apos_operacao'
    isSummerTime: boolean
    agentName: string | null
    branchName: string | null
    productName: string
    coin?: CoinInfo
    exchange: ExchangeInfo | null
}

export interface CreateProductInfo extends ProductInfo {
    groups: Group[]
    subGroups: SubGroup[] | null
    marks: Mark[]
    models: Model[] | null
    isEditing: boolean
    step: number
}

export interface ProductErrorInfo {
    name: string
    aplication: string
    selectedGroup: string
    selectedSubGroup: string
    selectedMark: string
    selectedModel: string
    stock: string
    minStock: string
    idealStock: string
    observation: string
    barcodes: string[]
    internalCodes: string[]
    buyPrice: string
    salePrice: string
    porcentGain: string
    qntPrice: string[]
}

export interface ProductInLoadStock {
    id: number
    qnt: number
    product: ProductInfo
}

export interface LoadStockInfo {
    id?: number
    products: ProductInLoadStock[]
    branch: BranchInfo | null
    date?: string
    hour?: string
    cashier?: Partial<CashierInfo>
    account?: Partial<AccountInfo>
    isSummerTime?: boolean
}

export interface ProductFilters {
    page: number
    rows: number
    rowsCount: number
    groups: Group[]
    subgroups: SubGroup[]
    marks: Mark[]
    models: Model[]
    suppliers: SupplierInfo[]
    branches: BranchInfo[]
    stockLevel: number | null
    orderBy: string | null
}

const productDefault: CreateProductInfo = {
    name: '',
    aplication: '',
    groups: [],
    selectedGroup: null,
    subGroups: null,
    selectedSubGroup: null,
    marks: [],
    selectedMark: null,
    models: null,
    selectedModel: null,
    minStock: 0,
    idealStock: 0,
    stock: 0,
    observation: '',
    barcodes: [''],
    internalCodes: [''],
    unitType: UnitType.Unit,
    buyPrice: 0,
    salePrice: 0,
    porcentGain: 0,
    qntPrice: [{ price: 0, qnt: 0 }],
    namedPrice: [],
    image: null,
    step: 1,
    iva: IvaEnum.Dez,
    showIva: false,
    sellWithoutStock: false,
    isEditing: false,
    isAtive: true,
    autoSum: true,
    autoPrice: false,
    supplier: null,
    localizations: [{ value: '' }],
    productsStockAfterOperation: [{ product: null, qnt: 0 }],
}

const productErrorDefault: ProductErrorInfo = {
    name: '',
    aplication: '',
    selectedGroup: '',
    selectedSubGroup: '',
    selectedMark: '',
    selectedModel: '',
    stock: '',
    minStock: '',
    idealStock: '',
    observation: '',
    barcodes: [''],
    internalCodes: [''],
    buyPrice: '',
    salePrice: '',
    porcentGain: '',
    qntPrice: [''],
}

export const ProductHistoricTypeLabel: Record<string, string> = {
    entrada: 'Compra',
    saida: 'Venta',
    carga_estoque: 'Carga de stock',
    transferencia_estoque: 'Transferência de stock',
    nota_credito: 'Nota de crédito',
    carga_estoque_apos_operacao: 'Carga stock após operacción',
}

export interface ProductContextInterface {
    productInfo: CreateProductInfo
    setProductInfo: Dispatch<SetStateAction<CreateProductInfo>>
    productErrorInfo: ProductErrorInfo
    setProductErrorInfo: Dispatch<SetStateAction<ProductErrorInfo>>
    validate(): boolean
    startNewProduct(): void
    editProduct(id: number): Promise<void>
    handleCreateNewGroup(name: string): Promise<void>
    handleCreateNewSubGroup(name: string, groupId: number): Promise<void>
    handleCreateNewMark(name: string): Promise<void>
    handleCreateNewModel(name: string, groupId: number): Promise<void>
    handleCreateNewProduct(): Promise<ProductInfo | null>
    products: ProductInfo[]
    setProducts: Dispatch<SetStateAction<ProductInfo[]>>
    selectedProducts: ProductInfo[]
    setSelectedProducts: Dispatch<SetStateAction<ProductInfo[]>>
    filters: ProductFilters
    setFilters: Dispatch<SetStateAction<ProductFilters>>
    search: string
    setSearch: Dispatch<SetStateAction<string>>
}

const productContext = createContext<ProductContextInterface>({
    productInfo: productDefault,
    setProductInfo: () => {},
    productErrorInfo: productErrorDefault,
    setProductErrorInfo: () => {},
    validate: () => {
        return false
    },
    startNewProduct: () => {},
    editProduct: async () => {},
    handleCreateNewGroup: async () => {},
    handleCreateNewSubGroup: async () => {},
    handleCreateNewMark: async () => {},
    handleCreateNewModel: async () => {},
    handleCreateNewProduct: async () => {
        return null
    },
    products: [],
    setProducts: () => {},
    selectedProducts: [],
    setSelectedProducts: () => {},
    filters: {
        page: 1,
        rows: 10,
        rowsCount: 0,
        groups: [],
        subgroups: [],
        marks: [],
        models: [],
        branches: [],
        suppliers: [],
        stockLevel: null,
        orderBy: null,
    },
    setFilters: () => {},
    search: '',
    setSearch: () => {},
})

export function useProduct() {
    return useContext(productContext)
}

interface Props {
    children: React.ReactNode
}

export function ProductProvider({ children }: Props) {
    const snack = useSnack()
    const loading = useLoading()
    const [productInfo, setProductInfo] =
        useState<CreateProductInfo>(productDefault)
    const [productErrorInfo, setProductErrorInfo] =
        useState<ProductErrorInfo>(productErrorDefault)
    const navigate = useNavigate()
    const { accountInfo } = useAccount()
    const [products, setProducts] = useState<ProductInfo[]>([])
    const [selectedProducts, setSelectedProducts] = useState<ProductInfo[]>([])
    const [filters, setFilters] = useState<ProductFilters>({
        page: 1,
        rows: 10,
        rowsCount: 0,
        groups: [],
        subgroups: [],
        marks: [],
        models: [],
        branches: [],
        suppliers: [],
        stockLevel: null,
        orderBy: null,
    })
    const [search, setSearch] = useState('')

    const canUpdateProduct =
        accountInfo?.isMaster ||
        accountInfo?.rules.findIndex(
            (r) =>
                r.group.toLowerCase() === 'produtos' &&
                r.name.toLowerCase() === 'atualizar'
        ) !== -1

    useEffect(() => {
        window.scroll(0, 0)
    }, [productInfo.step])

    useEffect(() => {
        if (!accountInfo) return
        if (
            accountInfo?.isMaster ||
            (accountInfo.rules.findIndex(
                (r) =>
                    r.group.toLowerCase() === 'produtos' &&
                    r.name.toLowerCase() === 'listar'
            ) !== -1 &&
                accountInfo.rules.findIndex(
                    (r) =>
                        r.group.toLowerCase() === 'moedas' &&
                        r.name.toLowerCase() === 'listar'
                )) !== -1
        ) {
            loadGroups()
            loadMarks()
        }
    }, [accountInfo])

    useEffect(() => {
        if (!productInfo.selectedGroup) return
        loadSubGroups(productInfo.selectedGroup.id)
    }, [productInfo.selectedGroup])

    useEffect(() => {
        if (!productInfo.selectedMark) return
        loadModels(productInfo.selectedMark.id)
    }, [productInfo.selectedMark])

    const startNewProduct = () => {
        setProductInfo((prev) => ({
            ...JSON.parse(JSON.stringify(productDefault)),
            groups: prev.groups,
            marks: prev.marks,
        }))
        setProductErrorInfo({ ...productErrorDefault })
    }

    const editProduct = async (id: number) => {
        try {
            const product = await getProductById(id)

            setProductInfo((prev) => ({
                ...product,
                groups: prev.groups,
                marks: prev.marks,
                subGroups: prev.subGroups,
                models: prev.models,
                step: 1,
                isEditing: true,
                qntPrice: product.qntPrice.length
                    ? product.qntPrice
                    : JSON.parse(JSON.stringify(productDefault.qntPrice)),
                localizations: product.localizations.length
                    ? product.localizations
                    : JSON.parse(JSON.stringify(productDefault.localizations)),
                productsStockAfterOperation: product.productsStockAfterOperation
                    .length
                    ? product.productsStockAfterOperation
                    : JSON.parse(
                          JSON.stringify(
                              productDefault.productsStockAfterOperation
                          )
                      ),
            }))
            setProductErrorInfo({ ...productErrorDefault })
        } catch (error) {
            console.log(error)
            snack.error('Produto não encontrado')
            throw new Error('Product not found')
        }
    }

    const loadGroups = async () => {
        loading.show()
        try {
            const groups = await getAllGroups()
            setProductInfo((prev) => ({
                ...prev,
                groups: groups,
            }))
        } catch (error) {
            snack.error('Erro ao carregar grupos')
        }
        loading.hide()
    }

    const loadSubGroups = async (id: number) => {
        loading.show()
        try {
            const subGroups = await getSubGroupsByGroupId(id)
            setProductInfo((prev) => ({
                ...prev,
                subGroups: subGroups,
            }))
        } catch (error) {
            snack.error('Erro ao carregar subgrupos')
        }
        loading.hide()
    }

    const loadMarks = async () => {
        loading.show()
        try {
            const marks = await getAllMarks()
            setProductInfo((prev) => ({
                ...prev,
                marks: marks,
            }))
        } catch (error) {
            snack.error('Erro ao carregar marcas')
        }
        loading.hide()
    }

    const loadModels = async (id: number) => {
        loading.show()
        try {
            const models = await getModelsByMarkId(id)
            setProductInfo((prev) => ({
                ...prev,
                models: models,
            }))
        } catch (error) {
            snack.error('Erro ao carregar modelos')
        }
        loading.hide()
    }

    const handleCreateNewGroup = async (name: string) => {
        if (!name) return
        loading.show()
        try {
            const newGroup = await createGroup(name)
            console.log(newGroup)
            const newGroups = [...productInfo.groups]
            newGroups.push(newGroup)
            setProductInfo((prev) => ({
                ...prev,
                groups: newGroups,
                selectedGroup: newGroup,
            }))
        } catch (error) {
            snack.error('Erro ao criar grupo')
        }
        loading.hide()
    }

    const handleCreateNewSubGroup = async (name: string, groupId: number) => {
        if (!name) return
        loading.show()
        try {
            const newSubGroup = await createSubGroup(name, groupId)
            const newSubGroups = productInfo.subGroups
                ? [...productInfo.subGroups]
                : []
            newSubGroups.push(newSubGroup)
            setProductInfo((prev) => ({
                ...prev,
                subGroups: newSubGroups,
                selectedSubGroup: newSubGroup,
            }))
        } catch (error) {
            snack.error('Erro ao criar subgrupo')
        }
        loading.hide()
    }

    const handleCreateNewMark = async (name: string) => {
        if (!name) return
        loading.show()
        try {
            const newMark = await createMark(name)
            const newMarks = [...productInfo.marks]
            newMarks.push(newMark)
            setProductInfo((prev) => ({
                ...prev,
                marks: newMarks,
                selectedMark: newMark,
            }))
        } catch (error) {
            snack.error('Erro ao criar marca')
        }
        loading.hide()
    }

    const handleCreateNewModel = async (name: string, markId: number) => {
        if (!name) return
        loading.show()
        try {
            const newModel = await createModel(name, markId)
            const newModels = productInfo.models ? [...productInfo.models] : []
            newModels.push(newModel)
            setProductInfo((prev) => ({
                ...prev,
                models: newModels,
                selectedModel: newModel,
            }))
        } catch (error) {
            snack.error('Erro ao criar modelo')
        }
        loading.hide()
    }

    console.log(productInfo)

    const handleCreateNewProduct = async (): Promise<ProductInfo | null> => {
        let product: ProductInfo | null = null
        loading.show()
        try {
            const requestInfo: CreateProductInterface = {
                nome: productInfo.name,
                stock_minimo: Number(productInfo.minStock) || null,
                stock_ideal: Number(productInfo.idealStock) || null,
                stock_atual: !productInfo.isEditing
                    ? Number(productInfo.stock) || null
                    : undefined,
                aplicacao: productInfo.aplication || null,
                observacao: productInfo.observation || null,
                custo_cif: null,
                id_grupo: productInfo.selectedGroup?.id ?? null,
                id_sub_grupo: productInfo.selectedSubGroup?.id ?? null,
                id_marca: productInfo.selectedMark?.id ?? null,
                id_modelo: productInfo.selectedModel?.id ?? null,
                codigo_barras: productInfo.barcodes.filter(
                    (barcode) => barcode
                ),
                codigo_interno: productInfo.internalCodes.filter(
                    (internalCode) => internalCode
                ),
                path_imagem: productInfo.image?.content ?? null,
                valor_iva:
                    productInfo.iva === IvaEnum.Exento
                        ? 0
                        : productInfo.iva === IvaEnum.Cinco
                          ? 5
                          : 10,
                flag_ativo: productInfo.isAtive ? 1 : 0,
                flag_autosoma: productInfo.autoSum ? 1 : 0,
                flag_autopreco: productInfo.autoPrice ? 1 : 0,
                flag_controlar_estoque: 1,
                flag_imprimir_fatura: productInfo.showIva ? 1 : 0,
                flag_sem_estoque: productInfo.sellWithoutStock ? 1 : 0,
                unit_measurement: productInfo.unitType,
                preço: productInfo.qntPrice
                    .filter((qntPrice) => qntPrice.price && qntPrice.qnt)
                    .map((qntPrice) => {
                        return {
                            valor: qntPrice.price,
                            quantidade: Number(qntPrice.qnt),
                        }
                    })
                    .concat([
                        {
                            quantidade: 1,
                            valor: productInfo.salePrice,
                        },
                    ]),
                preço_nome: productInfo.namedPrice.map((np) => ({
                    nome: np.name,
                    valor: np.price,
                    porc_lucro: np.porcentGain,
                    clientes: np.clients.map((c) => c.id as number),
                })),
                valor_compra: productInfo.buyPrice,
                porc_lucro: productInfo.porcentGain,
                id_fornecedor: productInfo.supplier?.id ?? null,
                localizations: productInfo.localizations
                    .filter((l) => l.value)
                    .map((l) => ({
                        valor: l.value,
                        id_filial: l.branchId ?? null,
                    })),
                productsStockAfterOperation:
                    productInfo.productsStockAfterOperation
                        .filter((v) => v.product)
                        .map((v) => ({
                            id_produto_carga: v.product?.id as number,
                            quantidade_produto: v.qnt,
                        })),
            }

            if (!productInfo.isEditing) {
                const id = await createProduct(requestInfo)
                product = { ...productInfo, id: id }
                snack.success('Producto criado com sucesso!')
            } else if (productInfo.id) {
                await updateProduct({
                    ...requestInfo,
                    id_produto: productInfo.id,
                })
                product = { ...productInfo }
                snack.success('Producto atualizado com sucesso!')
            }

            setSelectedProducts([])
        } catch (error: any) {
            if (error instanceof BarcodeAlreadyInUseError) {
                const newProductErrorInfo = { ...productErrorDefault }
                productInfo.barcodes.forEach((barcode, i) => {
                    if (error.barcodes.includes(barcode)) {
                        newProductErrorInfo.barcodes[i] =
                            'Código de barra já em uso'
                    }
                })
                setProductErrorInfo({ ...newProductErrorInfo })
            }
            snack.error('Erro ao criar produto')
        }
        loading.hide()

        return product
    }

    const validate = (): boolean => {
        let isOk = true
        const newProductErrorInfo = { ...productErrorDefault }

        if (!productInfo.isEditing || canUpdateProduct) {
            if (!productInfo.name) {
                newProductErrorInfo.name = 'Campo obrigatório'
                isOk = false
            }
            if (!productInfo.barcodes.filter((barcode) => barcode).length) {
                newProductErrorInfo.barcodes[0] = 'Campo obrigatório'
                isOk = false
            } else {
                productInfo.barcodes.forEach((barcode, i) => {
                    productInfo.barcodes.forEach((otherBarcode, j) => {
                        if (i === j || !barcode) return
                        if (barcode === otherBarcode) {
                            newProductErrorInfo.barcodes[i] =
                                'Os códigos de barra não podem ser iguais'
                            newProductErrorInfo.barcodes[j] =
                                'Os códigos de barra não podem ser iguais'
                            isOk = false
                        }
                    })
                })
            }

            if (productInfo.buyPrice <= 0) {
                newProductErrorInfo.buyPrice = 'Campo obrigatório'
                isOk = false
            }
            if (productInfo.porcentGain <= 0) {
                newProductErrorInfo.porcentGain = 'Campo obrigatório'
                isOk = false
            }
            if (productInfo.salePrice <= 0) {
                newProductErrorInfo.salePrice = 'Campo obrigatório'
                isOk = false
            }
        }

        setProductErrorInfo({ ...newProductErrorInfo })
        return isOk
    }

    return (
        <productContext.Provider
            value={{
                productInfo,
                setProductInfo,
                productErrorInfo,
                setProductErrorInfo,
                validate,
                startNewProduct,
                editProduct,
                handleCreateNewGroup,
                handleCreateNewSubGroup,
                handleCreateNewMark,
                handleCreateNewModel,
                handleCreateNewProduct,
                products,
                setProducts,
                selectedProducts,
                setSelectedProducts,
                filters,
                setFilters,
                search,
                setSearch,
            }}
        >
            {children}
        </productContext.Provider>
    )
}
