import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LeftPanel from '../LeftPanel'
import { flatNavMenu } from '../LeftPanel/nav'
import { useAccount } from '../../context/account_context'
import { useConfigs } from '../../context/configs_context'

interface Props {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => {
    const [drawerWidth] = useState(240)
    const [headerHeight] = useState(70)
    const [hideSideBar, setHideSideBar] = useState(false)
    const [hideHeader, setHideHeader] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    useEffect(() => {
        setHideSideBar(
            [
                '/system/sale/new',
                '/system/sale/edit',
                '/system/sale/budget/new',
                '/system/sale/budget/edit',
                '/system/buy/new',
                '/system/buy/edit',
                '/system/product/load-stock/new',
                '/system/dashboard',
                '/system/employee/manage',
                '/system/cashier/manage',
                '/system/product/transfer-stock/new',
                '/system/sale/credit-note/new',
            ].includes(location.pathname.replace(/\/\d+/g, ''))
        )
        setHideHeader(
            [
                '/system/sale/new',
                '/system/sale/edit',
                '/system/buy/new',
                '/system/buy/edit',
                '/system/product/transfer-stock/new',
                '/system/sale/budget/new',
                '/system/sale/budget/edit',
                '/system/sale/credit-note/new',
            ].includes(location.pathname.replace(/\/\d+/g, ''))
        )
    }, [location.pathname])

    useEffect(() => {
        if (!accountInfo) return
        const nav = flatNavMenu.find(
            (n) => n.link === location.pathname.replace(/\/\d+$/, '')
        )
        if (!nav?.validate(accountInfo, configs)) {
            navigate('/system')
        }
    }, [location.pathname, accountInfo])

    if (location.pathname.includes('/system/render-note'))
        return <>{children}</>

    return (
        <>
            <LeftPanel
                drawerWidth={drawerWidth}
                headerHeight={headerHeight}
                hideSideBar={hideSideBar}
                hideHeader={hideHeader}
            />
            <Box
                sx={{
                    width: {
                        lg: hideSideBar
                            ? '100%'
                            : `calc(100% - ${drawerWidth}px)`,
                    },
                    ml: {
                        lg: hideSideBar ? '0' : `${drawerWidth}px`,
                    },
                    minHeight: hideHeader
                        ? '100vh'
                        : `calc(100vh - ${headerHeight}px)`,
                    background: '#E5E4E2',
                    p: { xs: '5px', md: '30px 40px' },
                }}
            >
                {children}
            </Box>
        </>
    )
}

export default Layout
