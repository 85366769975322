import { anonymousClient } from '../context/client_context'
import { CoinInfo } from '../context/coin_context'
import {
    CreditNoteInfo,
    ParcelInfo,
    PaymentInSale,
    PreSaleInfo,
    ProductInSale,
    SaleInfo,
    SaleReportsInfo,
    SoldProductsInfo,
} from '../context/sale_context'
import { ListReturn } from '../utils/maks'
import { BranchApiData, parseBranch } from './branch'
import { BuyApiData, parseBuy } from './buy'
import { ClientApiData, parseClient } from './client'
import { DiscountApiData } from './discount'
import { EmployeeApiData, parseEmployee } from './employee'
import { get, post, put } from './generic'
import { NoteApiData, parseNote } from './note'
import { ParseProduct, ProductApiData } from './product'
import { parseSupplier, SupplierApiData } from './supplier'

export class CashierClosedSaleError extends Error {
    constructor() {
        super()
    }
}

export interface CreateSaleInterface {
    flag_forma_pagamento: number | null
    id_cliente?: number
    nota_entrega: string | null
    sale_product: {
        id_produto: number
        quantidade_produto: number
        desconto: number
        nome_preco?: string
        preco?: number
    }[]
    sale_payment?: CreateSalePaymentInterface[] | null
    id_moeda_troco: number | null
    desconto: number | null
    use_legal_note?: boolean | null
    id_moeda_fatura?: number | null
    id_filial_master?: number | null
    id_pre_venda?: number | null
    juros_inicial?: number
    id_funcionario?: number | null
    id_funcionario_pagamento?: number | null
    parcelas?: number[]
    isPix?: boolean
    isParaguayTransfer?: boolean
    porcentagem_guia?: number
}

export interface CreateSaleResponse {
    sale: SaleInfo
    pix: Pix | null
    paraguayTransfer: ParaguayTransfer | null
}

export interface UpdateSaleInterface {
    id_venda: number
    id_cliente?: number
    flag_forma_pagamento_exibicao?: number | null
    use_legal_note?: boolean
    id_moeda_fatura?: number | null
    id_funcionario?: number | null
    data_vencimento?: string
    sale_product?: {
        id_produto: number
        quantidade_produto: number
        desconto: number
        nome_preco?: string
        preco?: number
    }[]
    data_lancamento?: string
    hora_lancamento?: string
    nota_entrega?: string | null
    porcentagem_guia?: number
}

export interface FinalizePendingSaleInterface {
    id_venda: number
    flag_forma_pagamento: number | null
    sale_payment?: CreateSalePaymentInterface[] | null
    id_moeda_troco: number
    desconto: number
    use_legal_note?: boolean | null
    id_moeda_fatura?: number | null
    juros_inicial?: number
    parcelas?: number[]
    id_funcionario_pagamento?: number | null
    isPix?: boolean
    isParaguayTransfer?: boolean
}

export interface FinalizePendingSaleResponse {
    pix: Pix | null
    paraguayTransfer: ParaguayTransfer | null
}

export interface ListSalesInterface {
    skip?: number
    take?: number
    coins: CoinInfo[]
    id_cliente?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_funcionario?: number[]
    id_filial?: number[]
    search?: string
    status?: number[]
    data_inicial?: string
    data_final?: string
    id_caixa_historico?: number
    listErroredNotes?: boolean
}

export interface ListReportsInterface {
    coins: CoinInfo[]
    id_cliente?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_funcionario?: number[]
    id_filial?: number[]
    search?: string
    status?: number[]
    data_inicial?: string
    data_final?: string
    id_caixa_historico?: number
    listErroredNotes?: boolean
}

export interface GenerateSaleReportsInterface {
    id_cliente?: number[]
    id_caixa?: number[]
    id_usuario?: number[]
    id_funcionario?: number[]
    id_filial?: number[]
    search?: string
    status?: number[]
    data_inicial?: string
    data_final?: string
    id_caixa_historico?: number
    selectedFilters: Record<string, string>
    docType: 'PDF' | 'CSV'
    listErroredNotes?: boolean
}

export interface ListSoldProductsInterface {
    skip?: number
    take?: number
    data_inicial?: string
    data_final?: string
    hora_inicial?: string
    productIds?: number[]
    id_cliente?: number[]
    id_grupo?: number[]
    id_fornecedor?: number[]
    order_by?: number
    selectedFilters?: Record<string, string>
}

interface ProductInSaleApiData {
    id_produto: number
    id_produto_venda: number
    id_venda: number
    preco_venda: string
    preco_compra: string
    preco_unitario: string
    desconto: string
    produto: ProductApiData
    quantidade_produto: number
    valor_iva: string
    id_desconto: number | null
    desconto_sistema: DiscountApiData
    preco_selecionado?: string
    data?: string
    hora?: string
    flag_horario_verao: number
    id_venda_anterior?: number
    vendaAnterior?: SaleApiData
    valorMoedaSecundaria?: {
        iso_moeda: string
        id_moeda: number
        preco_venda: string
        preco_compra: string
        preco_unitario: string
    }[]
}

interface SaleApiData {
    cliente: ClientApiData | null
    data_lancamento: string
    data_vencimento: string
    flag_forma_pagamento: number
    flag_forma_pagamento_exibicao: number
    hora_lancamento: string
    hora_vencimento: string
    id_cliente: number
    id_moeda: number
    id_usuario: number
    id_venda: number
    iva_5_porcento: string
    iva_10_porcento: string
    iva_total: string
    numero_caixa: number
    numero_fatura_legal: string
    juros_inicial: string
    produto_vendas: ProductInSaleApiData[]
    status_venda: number
    total_items: number
    valor_total: string
    valor_pago: string
    valor_juros: string
    valor_total_exento: string
    valor_total_5_porcento: string
    valor_total_10_porcento: string
    usuario: {
        nome: string
    }
    flag_horario_verao: number
    nota: NoteApiData | null
    filial: BranchApiData | null
    caixa: {
        nome: string
    } | null
    nota_entrega: string | null
    pagamentos?: SalePaymentApiData[]
    cdc_fatura?: string
    qrcode?: string
    data_faturamento?: string
    hora_faturamento?: string
    flag_horario_verao_faturamento?: number
    funcionario: EmployeeApiData | null
    status_fatura_eletronica?: number
    log_envio_fatura?: string
    flag_venda_juntada?: number
    notasCredito?: {
        notaCredito: CreditNoteApiData
    }[]
    id_filial?: number
    totalMoedaSecundaria?: {
        cambio: string
        iso_moeda: string
        id_moeda: number
        iva_5_porcento: string
        iva_10_porcento: string
        iva_total: string
        valor_total_exento: string
        valor_total_5_porcento: string
        valor_total_10_porcento: string
        valor_total: string
    }[]
    porcentagem_guia: string
}

interface SaleReportsApiData {
    coinId: number
    totalSold: number
    totalFees: number
    totalPaid: number
    totalCost: number
    totalGain: number
    totalSales: number
    totalInCreditNotes: number
    totalDebt: number
}

export interface CreateSalePaymentInterface {
    id_moeda: number
    valor_pagamento: number
    id_metodo_pagamento: number
    comprovante_texto?: string
    comprovante_imagem?: string
    id_funcionario?: number | null
}

export interface CreateSalePaymentEndpointInterface {
    id_venda?: number | null
    id_parcela?: number | null
    payments: CreateSalePaymentInterface[]
    id_moeda_troco: number
    desconto: number
    id_funcionario_pagamento?: number | null
    isPix?: boolean
    isParaguayTransfer?: boolean
}

export interface CreateSalePaymentEndpointResponse {
    ids: number[]
    pix: Pix | null
    paraguayTransfer: ParaguayTransfer | null
}

export interface SalePaymentApiData {
    data_lancamento: string
    hora_lancamento: string
    id_cliente: number
    id_moeda: number
    id_moeda_troco: number
    id_usuario: number
    id_venda: number
    id_venda_pagamento: number
    valor_pagamento: string
    desconto: string
    valor_moeda_secundaria: string
    troco_moeda_secundaria: string
    flag_cambio_reverso: number | null
    cambio: string
    caixa: {
        nome: string
    } | null
    usuario: {
        nome: string
    } | null
    id_metodo_pagamento: number
    metodoPagamento: {
        nome: string
        flag_ativo: number
    }
    comprovante_texto: string | null
    comprovante_imagem: string | null
    troco: string
    flag_horario_verao: number
    status_pagamento: number
    entrada_total: string
    funcionario: EmployeeApiData | null
    numero_fatura: string
    nota: NoteApiData | null
    data_faturamento?: string
    hora_faturamento?: string
    flag_horario_verao_faturamento?: number
    venda?: SaleApiData
    flag_pagamento_juntado: number
    pagamentosAnteriores?: {
        pagamentoAnterior: SalePaymentApiData
    }[]
}

export interface SoldProductsApiData {
    productId: number
    product: string
    averageBuyPrice: string
    averageSalePrice: string
    qntSold: string
    qntSales: string
    coinId: number
}

export interface CreatePreSaleInterface {
    titulo: string
    produtos: {
        id_produto: number
        quantidade_produto: number
        preco_selecionado?: string
        desconto?: number
    }[]
    flag_ativo: number
    anotacao: string | null
    id_cliente: number | null
    id_filial_master?: number | null
}

export interface UpdatePreSaleInterface
    extends Partial<CreatePreSaleInterface> {
    id_pre_venda: number
}

interface PreSaleApiData {
    id_pre_venda: number
    titulo: string
    data: string
    hora: string
    id_caixa: number
    id_usuario: number
    flag_horario_verao: number
    flag_ativo: number
    cliente: ClientApiData | null
    anotacao: string | null
    id_filial: number | null
    caixa: {
        nome: string
    } | null
    usuario: {
        nome: string
    } | null
    produtosPreVenda: {
        id_produto_pre_venda: number
        id_produto: number
        produto: ProductApiData
        quantidade_produto: string
        desconto: string
        preco_venda: string
        preco_selecionado?: string
    }[]
    valor_total: string
    filial: BranchApiData | null
}

export interface ListPreSalesInterface {
    search?: string
    skip?: number
    take?: number
    flag_ativo?: number
}

export interface ParcelApiData {
    id_parcela: number
    valor_total: string
    valor_pago: string
    valor_juros: string
    status_parcela: number
    id_venda: number
    data_vencimento: string | null
    hora_vencimento: string | null
    pagamentos: SalePaymentApiData[]
}

export interface ListParcelsInterface {
    id_venda?: number
    id_filial?: number[]
    skip?: number
    take?: number
}

export interface CreateCreditNote {
    id_operacoes: number[]
    flag_tipo_nota_credito: number
    produtos: {
        id_produto: number
        quantidade: number
        preco: number
    }[]
    changeStock?: boolean
    id_cliente?: number
    id_fornecedor?: number
    id_filial_master?: number
}

export interface UpdateCreditNote {
    id_nota_credito: number
    use_legal_note?: boolean
    numero_fatura?: string
}

export interface FindCreditNote {
    id_venda?: number
    id_compra?: number
    skip?: number
    take?: number
}

export interface CreditNoteApiData {
    id_nota_credito: number
    data: string
    hora: string
    id_usuario: number
    id_venda: number
    iva_5_porcento: string
    iva_10_porcento: string
    iva_total: string
    numero_fatura: string
    produtosNotaCredito: {
        id_produto_nota_credito: number
        quantidade_produto: string
        preco: string
        preco_unitario: string
        valor_iva: string
        produto: ProductApiData
    }[]
    total_items: number
    valor_total: string
    valor_total_exento: string
    valor_total_5_porcento: string
    valor_total_10_porcento: string
    usuario: {
        nome: string
    }
    flag_horario_verao: number
    flag_tipo_nota_credito: number
    nota: NoteApiData | null
    caixa: {
        nome: string
    } | null
    cdc_fatura?: string
    qrcode?: string
    status_fatura_eletronica?: number
    log_envio_fatura?: string
    data_faturamento?: string
    hora_faturamento?: string
    flag_horario_verao_faturamento?: number
    id_moeda?: number
    operacoes?: {
        venda?: SaleApiData
        compra?: BuyApiData
    }[]
    filial: BranchApiData | null
    cliente: ClientApiData | null
    fornecedor: SupplierApiData | null
}

export interface UpdateSalePaymentSale {
    id_venda_pagamento: number
    id_funcionario?: number | null
    use_legal_note?: boolean
}

export interface JoinSales {
    id_venda: number[]
}

export interface JoinPayments {
    id_venda_pagamento: number[]
}

export interface FixEletronicNote {
    id_venda?: number
    id_nota_credito?: number
    id_transferencia_estoque?: number
}

export interface ListSalePayments {
    id_venda?: number[]
    skip?: number
    take?: number
    search?: string
}

export enum PixStatusEnum {
    Pending = 0,
    Paid = 1,
    Refunded = 2,
    Expired = 3,
    RefundInProgress = 4
}

export const PixStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Pago',
    2: 'Devolvido',
    3: 'Expirado',
    4: 'Devolução em processo'
}

export interface Pix {
    id_pix: number
    valor: number
    flag_status: number
    data_cadastro: Date
    data_pagamento?: Date | null
    data_expiracao?: Date | null
    externalId?: string | null
    qrcode?: string | null
    flag_liquidado: number
}

export interface ListPix {
    skip?: number
    take?: number
    flag_status?: number
    flag_liquidado?: number
}

export enum ParaguayTransferStatusEnum {
    Pending = 0,
    Paid = 1,
    Refunded = 2,
    Expired = 3,
}

export interface ParaguayTransfer {
    id_transferencia_paraguai: number
    valor: number
    flag_status: number
    data_cadastro: Date
    data_pagamento?: Date | null
    data_expiracao?: Date | null
    data_liquidacao?: Date | null
    externalId?: string | null
}

function parseSaleProduct(
    p: ProductInSaleApiData,
    coins: CoinInfo[]
): ProductInSale {
    return {
        id: p.id_produto_venda,
        price: Number(p.preco_venda),
        unitPrice: Number(p.preco_unitario),
        qnt: Number(p.quantidade_produto),
        subtotal: Number(p.preco_venda) * Number(p.quantidade_produto),
        product: ParseProduct(p.produto),
        discount: Number(p.desconto),
        buyPrice: Number(p.preco_compra),
        ivaValue: Number(p.valor_iva),
        systemDiscount: p.id_desconto
            ? {
                  id: p.desconto_sistema.id_desconto,
                  title: p.desconto_sistema.titulo,
              }
            : null,
        selectedNamedPrice: p.preco_selecionado,
        date: p.data,
        hour: p.hora,
        isSummerTime: Boolean(p.flag_horario_verao),
        prevSaleId: p.id_venda_anterior,
        prevSale: p.vendaAnterior
            ? parseSale(p.vendaAnterior, coins)
            : undefined,
        valueSecondaryCoin: p.valorMoedaSecundaria
            ? p.valorMoedaSecundaria.map((v) => ({
                  iso: v.iso_moeda,
                  coinId: v.id_moeda,
                  buyPrice: Number(v.preco_compra),
                  price: Number(v.preco_venda),
                  unitPrice: Number(v.preco_unitario),
              }))
            : [],
    }
}

function parseSale(data: SaleApiData, coins: CoinInfo[]): SaleInfo {
    return {
        bill: data.numero_fatura_legal,
        client: data.cliente ? parseClient(data.cliente) : anonymousClient,
        observation: data.nota_entrega ? data.nota_entrega : '',
        payments: data.pagamentos
            ? data.pagamentos.map((pagamento) => parseSalePayment(pagamento))
            : [],
        paymentType: data.flag_forma_pagamento,
        paymentTypeShow: data.flag_forma_pagamento_exibicao,
        coin: coins?.filter((c) => c.id === data.id_moeda)[0],
        total: Number(data.valor_total),
        totalIva: Number(data.iva_total),
        totalIvaFivePercent: Number(data.iva_5_porcento),
        totalIvaTenPercent: Number(data.iva_10_porcento),
        totalAmoutPayed: Number(data.valor_pago),
        totalFees: Number(data.valor_juros),
        totalExcept: Number(data.valor_total_exento),
        totalFivePercent: Number(data.valor_total_5_porcento),
        totalTenPercent: Number(data.valor_total_10_porcento),
        date: data.data_lancamento,
        hour: data.hora_lancamento,
        expDate: data.data_vencimento,
        expHour: data.hora_vencimento,
        id: data.id_venda,
        initialFees: Number(data.juros_inicial),
        products:
            data.produto_vendas?.map((p) => parseSaleProduct(p, coins)) ?? [],
        saleStatus: data.status_venda,
        cashier: data.caixa
            ? {
                  id: 0,
                  hash: '',
                  name: data.caixa.nome,
                  isActive: false,
                  branch: null,
              }
            : undefined,
        account: data.usuario
            ? {
                  id: 0,
                  name: data.usuario.nome,
                  username: '',
                  password: '',
                  cpassword: '',
                  rules: [],
                  isMaster: false,
                  isActive: false,
                  loginWithImage: false,
                  image: null,
                  branch: null,
              }
            : undefined,
        isSummerTime: Boolean(data.flag_horario_verao),
        note: data.nota ? parseNote(data.nota) : null,
        branch: data.filial ? parseBranch(data.filial) : null,
        cdc: data.cdc_fatura,
        qrCode: data.qrcode,
        noteDate: data.data_faturamento,
        noteHour: data.hora_faturamento,
        isNoteSummerTime: Boolean(data.flag_horario_verao_faturamento),
        employee: data.funcionario
            ? parseEmployee(data.funcionario)
            : undefined,
        statusEletronicNote: data.status_fatura_eletronica,
        logEletronicNote: data.log_envio_fatura,
        creditNotes: data.notasCredito
            ? data.notasCredito.map((nc) =>
                  parseCreditNote(nc.notaCredito, coins)
              )
            : undefined,
        isJoinedNote: Boolean(data.flag_venda_juntada),
        branchId: data.id_filial,
        totalSecondaryCoin: data.totalMoedaSecundaria
            ? data.totalMoedaSecundaria.map((t) => ({
                  exchange: Number(t.cambio),
                  iso: t.iso_moeda,
                  coinId: t.id_moeda,
                  totalIvaFivePercent: Number(t.iva_5_porcento),
                  totalIvaTenPercent: Number(t.iva_10_porcento),
                  totalIva: Number(t.iva_total),
                  totalExcept: Number(t.valor_total_exento),
                  totalFivePercent: Number(t.valor_total_5_porcento),
                  totalTenPercent: Number(t.valor_total_10_porcento),
                  total: Number(t.valor_total),
              }))
            : [],
        guidePercentage: Number(data.porcentagem_guia),
    }
}

function parseSalePayment(
    data: SalePaymentApiData,
    coins?: CoinInfo[]
): PaymentInSale {
    return {
        id: data.id_venda_pagamento,
        amountPayed: Number(data.valor_pagamento),
        discount: Number(data.desconto),
        paymentCoin: coins?.find((c) => c.id === data.id_moeda) ?? null,
        changeCoin: coins?.find((c) => c.id === data.id_moeda_troco) ?? null,
        paymentMethod: data.metodoPagamento
            ? {
                  id: data.id_metodo_pagamento,
                  name: data.metodoPagamento.nome,
                  isActive: Boolean(data.metodoPagamento.flag_ativo),
              }
            : null,
        exchange: Number(data.cambio)
            ? {
                  exchangeValue: Number(data.cambio),
                  exchangeAmountPayed: Number(data.valor_moeda_secundaria),
                  flagReverseExchange: data.flag_cambio_reverso ?? 0,
              }
            : undefined,
        date: data.data_lancamento,
        hour: data.hora_lancamento,
        cashier: data.caixa
            ? {
                  id: 0,
                  hash: '',
                  name: data.caixa.nome,
                  isActive: false,
                  branch: null,
              }
            : undefined,
        madeBy: data.usuario
            ? {
                  id: 0,
                  name: data.usuario.nome,
                  username: '',
                  password: '',
                  cpassword: '',
                  rules: [],
                  isMaster: false,
                  isActive: false,
                  loginWithImage: false,
                  image: null,
                  branch: null,
              }
            : null,
        proofText: data.comprovante_texto ?? undefined,
        proofImage: data.comprovante_imagem ?? undefined,
        change: Number(data.troco),
        isSummerTime: Boolean(data.flag_horario_verao),
        paymentStatus: data.status_pagamento,
        exchangeChange: data.troco_moeda_secundaria
            ? Number(data.troco_moeda_secundaria)
            : null,
        bill: data.numero_fatura,
        note: data.nota ? parseNote(data.nota) : null,
        noteDate: data.data_faturamento,
        noteHour: data.hora_faturamento,
        isNoteSummerTime: Boolean(data.flag_horario_verao_faturamento),
        employee: data.funcionario
            ? parseEmployee(data.funcionario)
            : undefined,
        sale: data.venda && coins ? parseSale(data.venda, coins) : undefined,
        isJoinedPayment: Boolean(data.flag_pagamento_juntado),
        prevPayments: data.pagamentosAnteriores
            ? data.pagamentosAnteriores.map((p) =>
                  parseSalePayment(p.pagamentoAnterior, coins)
              )
            : undefined,
    }
}

function parseSaleReport(
    data: SaleReportsApiData,
    coins: CoinInfo[]
): SaleReportsInfo {
    return {
        coinId: data.coinId,
        totalSold: data.totalSold,
        totalFees: data.totalFees,
        totalPaid: data.totalPaid,
        totalCost: data.totalCost,
        totalGain: data.totalGain,
        totalSales: data.totalSales,
        totalDebt: data.totalDebt,
        totalInCreditNotes: data.totalInCreditNotes,
        coin: coins.find((c) => c.id === data.coinId),
    }
}

function parseSoldProducts(
    data: SoldProductsApiData,
    coins: CoinInfo[]
): SoldProductsInfo {
    return {
        productId: data.productId,
        product: data.product,
        averageBuyPrice: Number(data.averageBuyPrice),
        averageSalePrice: Number(data.averageSalePrice),
        qntSales: Number(data.qntSales),
        qntSold: Number(data.qntSold),
        coin: coins.find((c) => c.id === data.coinId),
    }
}

function parsePreSale(data: PreSaleApiData): PreSaleInfo {
    return {
        id: data.id_pre_venda,
        title: data.titulo,
        products: data.produtosPreVenda.map((p, i) => {
            const product = ParseProduct(p.produto)

            return {
                id: p.id_produto_pre_venda,
                product: product,
                qnt: Number(p.quantidade_produto),
                price: Number(p.preco_venda),
                subtotal: Number(p.preco_venda) * Number(p.quantidade_produto),
                discount: Number(p.desconto) * 100,
                selectedNamedPrice: p.preco_selecionado,
            }
        }),
        branch: data.filial ? parseBranch(data.filial) : null,
        total: Number(data.valor_total),
        totalIva: 0,
        client: data.cliente ? parseClient(data.cliente) : anonymousClient,
        annotation: data.anotacao,
        cashier: data.caixa
            ? {
                  id: 0,
                  hash: '',
                  name: data.caixa.nome,
                  isActive: false,
                  branch: null,
              }
            : undefined,
        account: data.usuario
            ? {
                  id: 0,
                  name: data.usuario.nome,
                  username: '',
                  password: '',
                  cpassword: '',
                  rules: [],
                  isMaster: false,
                  isActive: false,
                  loginWithImage: false,
                  image: null,
                  branch: null,
              }
            : undefined,
        date: data.data,
        hour: data.hora,
        isSummerTime: Boolean(data.flag_horario_verao),
        isActive: Boolean(data.flag_ativo),
        productCount:
            data.produtosPreVenda[data.produtosPreVenda.length - 1]
                .id_produto_pre_venda,
    }
}

export function parseParcel(
    data: ParcelApiData,
    parcelNumber: number
): ParcelInfo {
    return {
        id: data.id_parcela,
        saleId: data.id_venda,
        endDate: data.data_vencimento as string,
        endHour: data.hora_vencimento as string,
        status: data.status_parcela,
        total: Number(data.valor_total),
        totalPaid: Number(data.valor_pago),
        totalFees: Number(data.valor_juros),
        payments: data.pagamentos.map((pagamento) =>
            parseSalePayment(pagamento)
        ),
        parcelNumber,
    }
}

export function parseCreditNote(
    data: CreditNoteApiData,
    coins: CoinInfo[]
): CreditNoteInfo {
    return {
        bill: data.numero_fatura,
        total: Number(data.valor_total),
        totalIva: Number(data.iva_total),
        totalIvaFivePercent: Number(data.iva_5_porcento),
        totalIvaTenPercent: Number(data.iva_10_porcento),
        totalExcept: Number(data.valor_total_exento),
        totalFivePercent: Number(data.valor_total_5_porcento),
        totalTenPercent: Number(data.valor_total_10_porcento),
        date: data.data,
        hour: data.hora,
        id: data.id_nota_credito,
        products:
            data.produtosNotaCredito?.map((p) => ({
                id: p.id_produto_nota_credito,
                qnt: Number(p.quantidade_produto),
                price: Number(p.preco),
                ivaValue: Number(p.valor_iva),
                unitPrice: Number(p.preco_unitario),
                subtotal: Number(p.preco) * Number(p.quantidade_produto),
                product: ParseProduct(p.produto),
            })) ?? [],
        cashier: data.caixa
            ? {
                  name: data.caixa.nome,
              }
            : undefined,
        account: data.usuario
            ? {
                  name: data.usuario.nome,
              }
            : undefined,
        isSummerTime: Boolean(data.flag_horario_verao),
        note: data.nota ? parseNote(data.nota) : null,
        cdc: data.cdc_fatura,
        qrCode: data.qrcode,
        statusEletronicNote: data.status_fatura_eletronica,
        logEletronicNote: data.log_envio_fatura,
        noteDate: data.data_faturamento,
        noteHour: data.hora_faturamento,
        isNoteSummerTime: Boolean(data.flag_horario_verao_faturamento),
        type: data.flag_tipo_nota_credito,
        operations: data.operacoes
            ? data.operacoes.map((o) => ({
                  sale: o.venda ? parseSale(o.venda, coins) : undefined,
                  buy: o.compra ? parseBuy(o.compra, coins) : undefined,
              }))
            : undefined,
        coin: coins?.find((c) => c.id === data.id_moeda) ?? undefined,
        branch: data.filial ? parseBranch(data.filial) : undefined,
        client: data.cliente ? parseClient(data.cliente) : undefined,
        supplier: data.fornecedor ? parseSupplier(data.fornecedor) : undefined,
    }
}

export async function createSale(
    data: CreateSaleInterface,
    coins: CoinInfo[]
): Promise<CreateSaleResponse> {
    try {
        const res = await post('sale', data, {
            useRecaptcha: false,
        })
        return {
            sale: parseSale(res.data.data.sale, coins),
            pix: res.data.data.pix,
            paraguayTransfer: res.data.data.paraguayTransfer,
        }
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function updateSale(data: UpdateSaleInterface): Promise<void> {
    await put('sale', data, {
        useRecaptcha: true,
    })
}

export async function finalizePendingSale(
    data: FinalizePendingSaleInterface
): Promise<FinalizePendingSaleResponse> {
    try {
        const res = await post('sale/finalize', data, {
            useRecaptcha: false,
        })
        return {
            pix: res.data.data.pix,
            paraguayTransfer: res.data.data.paraguayTransfer,
        }
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function getSaleById(
    saleId: number,
    coins: CoinInfo[]
): Promise<SaleInfo> {
    const res = await post(`sale/specific`, { id_venda: saleId })
    return parseSale(res.data.data.sale, coins)
}

export async function listSales(
    data: ListSalesInterface
): Promise<ListReturn<SaleInfo>> {
    const res = await post('sale/list', {
        skip: data.skip,
        take: data.take,
        search: data.search,
        id_cliente: data.id_cliente,
        id_caixa: data.id_caixa,
        status: data.status,
        data_inicial: data.data_inicial,
        data_final: data.data_final,
        id_usuario: data.id_usuario,
        id_funcionario: data.id_funcionario,
        id_caixa_historico: data.id_caixa_historico,
        id_filial: data.id_filial,
        listErroredNotes: data.listErroredNotes,
    })
    return {
        data: res.data.data.data.map((apiData: SaleApiData) =>
            parseSale(apiData, data.coins)
        ),
        count: res.data.data.count,
    }
}

export async function listSaleProducts(
    id_venda: number,
    coins: CoinInfo[]
): Promise<ProductInSale[]> {
    const res = await get(`sale-products/${id_venda}`)
    return res.data.data.map((apiData: ProductInSaleApiData) =>
        parseSaleProduct(apiData, coins)
    )
}

export async function cancelSale(saleId: number): Promise<void> {
    await post(
        `sale/cancel`,
        { id_venda: saleId },
        {
            useRecaptcha: true,
        }
    )
}

export async function listSaleReports(
    data: ListReportsInterface
): Promise<SaleReportsInfo[]> {
    const res = await post('reports/sale', {
        search: data.search,
        id_cliente: data.id_cliente,
        id_caixa: data.id_caixa,
        status: data.status,
        data_inicial: data.data_inicial,
        data_final: data.data_final,
        id_usuario: data.id_usuario,
        id_funcionario: data.id_funcionario,
        id_filial: data.id_filial,
        id_caixa_historico: data.id_caixa_historico,
        listErroredNotes: data.listErroredNotes,
    })

    return res.data.data.map((apiData: SaleReportsApiData) =>
        parseSaleReport(apiData, data.coins)
    )
}

export async function generateSaleReports(
    data: GenerateSaleReportsInterface
): Promise<void> {
    await post(
        'reports/sale/generate',
        {
            search: data.search,
            id_cliente: data.id_cliente,
            id_caixa: data.id_caixa,
            status: data.status,
            data_inicial: data.data_inicial,
            data_final: data.data_final,
            id_usuario: data.id_usuario,
            id_funcionario: data.id_funcionario,
            id_filial: data.id_filial,
            id_caixa_historico: data.id_caixa_historico,
            selectedFilters: data.selectedFilters,
            docType: data.docType,
            listErroredNotes: data.listErroredNotes,
        },
        {
            useRecaptcha: true,
        }
    )
}

export async function createSalePayment(
    data: CreateSalePaymentEndpointInterface
): Promise<CreateSalePaymentEndpointResponse> {
    try {
        const res = await post('sale-payment', data, {
            useRecaptcha: true,
        })

        return {
            ids: res.data.data.payments.map((p: any) => p.id_venda_pagamento),
            pix: res.data.data.pix,
            paraguayTransfer: res.data.data.paraguayTransfer,
        }
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function listSalePayments(
    data: ListSalePayments,
    coins?: CoinInfo[]
): Promise<ListReturn<PaymentInSale>> {
    const res = await post('sale-payments', data)
    return {
        data: res.data.data.data.map((p: SalePaymentApiData) =>
            parseSalePayment(p, coins)
        ),
        count: res.data.data.count,
    }
}

export async function listSoldProducts(
    data: ListSoldProductsInterface,
    coins: CoinInfo[]
): Promise<SoldProductsInfo[]> {
    const res = await post('reports/sold-products', data)
    return res.data.data.map((data: SoldProductsApiData) =>
        parseSoldProducts(data, coins)
    )
}

export async function generateSoldProducts(
    data: ListSoldProductsInterface
): Promise<void> {
    await post('reports/sold-products/generate', data, {
        useRecaptcha: true,
    })
}

export async function cancelSalePayment(salePaymentId: number): Promise<void> {
    await post(
        `sale-payment/cancel`,
        { id_venda_pagamento: salePaymentId },
        {
            useRecaptcha: true,
        }
    )
}

export async function updateSalePayment(
    data: UpdateSalePaymentSale
): Promise<void> {
    await put(`sale-payment`, data, {
        useRecaptcha: true,
    })
}

export async function createPreSale(
    data: CreatePreSaleInterface
): Promise<void> {
    try {
        await post('pre-sale', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function updatePreSale(
    data: UpdatePreSaleInterface
): Promise<void> {
    try {
        await put('pre-sale', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function listPreSales(
    data: ListPreSalesInterface
): Promise<ListReturn<PreSaleInfo>> {
    const res = await post('pre-sales', data)
    return {
        data: res.data.data.data.map(parsePreSale),
        count: res.data.data.count,
    }
}

export async function getPreSaleById(id: number): Promise<PreSaleInfo> {
    const res = await get(`pre-sale/${id}`)
    return parsePreSale(res.data.data)
}

export async function listParcels(
    data: ListParcelsInterface
): Promise<ParcelInfo[]> {
    const res = await post('parcels', data)
    return res.data.data.map((parcel: any, i: any) =>
        parseParcel(parcel, i + 1)
    )
}

export async function createCreditNote(data: CreateCreditNote): Promise<void> {
    try {
        await post('credit-note', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function updateCreditNote(data: UpdateCreditNote): Promise<void> {
    try {
        await put('credit-note', data, {
            useRecaptcha: true,
        })
    } catch (error: any) {
        if (error?.message === 'Cashier is not open')
            throw new CashierClosedSaleError()
        throw error
    }
}

export async function findCreditNote(
    data: FindCreditNote,
    coins: CoinInfo[]
): Promise<ListReturn<CreditNoteInfo>> {
    const res = await post(`credit-note/list`, data)
    return {
        count: res.data.data.count,
        data: res.data.data.data.map((creditNote: CreditNoteApiData) =>
            parseCreditNote(creditNote, coins)
        ),
    }
}

export async function findCreditNoteById(
    id: number,
    coins: CoinInfo[]
): Promise<CreditNoteInfo> {
    const res = await get(`credit-note/${id}`)

    return parseCreditNote(res.data.data, coins)
}

export async function joinSales(data: JoinSales): Promise<void> {
    await post('sale/join', data, {
        useRecaptcha: true,
    })
}

export async function joinPayments(data: JoinPayments): Promise<void> {
    await post('sale-payment/join', data, {
        useRecaptcha: true,
    })
}

export async function getPixById(id: number): Promise<Pix> {
    const res = await get(`pix/${id}`)
    return res.data.data
}

export async function refundPix(id: number): Promise<void> {
    await post(`pix/refund/${id}`, {})
}

export async function listPix(data: ListPix): Promise<ListReturn<Pix>> {
    const res = await post('pix/list', data)
    return res.data.data
}

export async function getParaguayTransferById(
    id: number
): Promise<ParaguayTransfer> {
    const res = await get(`paraguay-transfer/${id}`)
    return res.data.data
}

export async function fixEletronicNote(data: FixEletronicNote): Promise<void> {
    const res = await post('fix-eletronic-note', data, {
        useRecaptcha: true,
    })
    return res.data.data
}
